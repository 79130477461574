body {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background: #D7D7D7;
  border-radius: 500px;
  border: none;
  height: 20px;
}
::-webkit-scrollbar-thumb:hover {
  background: #949494;
}