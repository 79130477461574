@font-face {
  font-family: "Montserrat-Regular";
  src: url("./fonts/Montserrat/Montserrat-Regular.ttf"), local("Montserrat-Regular");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("./fonts/Montserrat/Montserrat-Regular.ttf"), local("Montserrat-Bold");
}

@font-face {
  font-family: "Montserrat-Light";
  src: url("./fonts/Montserrat/Montserrat-Light.ttf"), local("Montserrat-Light");
}

@font-face {
  font-family: "Montserrat-ExtraLight";
  src: url("./fonts/Montserrat/Montserrat-ExtraLight.ttf"), local("Montserrat-ExtraLight");
}

@font-face {
  font-family: "Roboto Mono";
  src: url("./fonts/RobotoMono/static/RobotoMono-Medium.ttf"), local("Roboto Mono");
}

.App {
  overflow: hidden;
}

.App-layout {
  background: url("./assets/bg-app.png") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.auth-layouth {
  width: 100%;
  display: flex;
  /*justify-content: center;*/
  flex-direction: column;
  align-items: center;
}

//Connection popup
.mini-popup:where(.css-dev-only-do-not-override-1okl62o).ant-modal .ant-modal-content {
  border-radius: 26px;
  background: linear-gradient(341deg, #EDEDED 12.22%, #FFF 86.92%);
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  padding: 39px 32px 48px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-family: Montserrat-Bold;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);

    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 24px;
    margin-bottom: 32px;

  }

}

:where(.css-dev-only-do-not-override-1okl62o).ant-modal .ant-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;

}

:where(.css-dev-only-do-not-override-1okl62o).ant-input-affix-wrapper-lg {
  width: 405px;
  display: inline-flex;
  height: 48px;
  padding: 0px 24px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  background: #FFF;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  border: unset;
}

:where(.css-dev-only-do-not-override-1okl62o).ant-input-outlined:hover {
  border: unset !important;
}

:where(.css-dev-only-do-not-override-1okl62o).ant-input-outlined:focus {
  border: unset !important;

}

:where(.css-dev-only-do-not-override-1okl62o).ant-input-affix-wrapper>input.ant-input {
  color: #949494;
  font-family: Montserrat-Light;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
}

.anticon svg {
  width: 24px;
  height: 26px;
  color: rgba(0, 0, 0, 0.13);
}

.line_2 {
  width: 163px;
  height: 1px;
  background: linear-gradient(90deg, rgba(195, 16, 29, 0) 0%, #75030B 100%);
}

.rotate {
  rotate: 180deg;
}

.connections {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  border-radius: 26px;
  background: #FFF;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  margin-top: 30px;

  &_item {
    display: flex;
    width: 405px;
    height: 48px;
    padding: 13px 24px;
    align-items: center;
    gap: 12px;
    cursor: pointer;

    span {
      color: #949494;
      font-family: Montserrat-Light;

      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 114.5%;
    }
  }
}

.borders {
  border-top: 1px solid rgba(0, 0, 0, 0.13);
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.more-wallets {
  color: #949494;
  font-family: Montserrat-Bold;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  margin-top: 30px;
  cursor: pointer;
}



.header {
  width: 100vw;
  background: #FFFFFF;
  position: fixed;
  display: flex;
  justify-content: center;
  box-shadow: 0px 4px 4px 0px rgba(188, 188, 188, 0.25);
  z-index: 990000000;

}

.web-header {
  width: 1240px;
  height: 95px;
  background: white;
  /*padding: 0 140px;*/
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*position: fixed;*/
  /*right: 0;*/
  /*left: 0;*/
  z-index: 5;
  /*box-shadow: 0px 4px 4px 0px rgba(188, 188, 188, 0.25);*/
  position: relative;

}

.logo {
  cursor: pointer;
  transition: 0.5s;

  img {
    width: 185px;
  }
}

.web-header-group-items {
  display: flex;
  align-items: center;
  gap: 57px;
  z-index: 5;
}

.navbar {
  display: flex;
  align-items: center;
  gap: 18px;
  transition: 1s ease;
  /*opacity: 1;*/
  position: absolute;
  left: 21%;
}

.hide-nav {
  transition: 0.5s ease;
  width: 100%;
  //overflow: hidden;
  opacity: 0;
  animation: hidenavbar 0.5s ease;
}

@keyframes hidenavbar {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.navbar-item {
  background: linear-gradient(to right, #949494 18.3%, #930210 99.43%, #B4001E 60.57%, #DA291C 81.7%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 500% 100%;
  background-position: left center;
  transition: background 0.5s linear;
  font-family: Montserrat-Light;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  white-space: nowrap;
}

.navbar-item:hover {
  background-position: 100% center;
}
.navbar-item-is-active {
  background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.login {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-right: 142px;
}
.login-connect {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-right: 37px;
}

.navbar-item-market_nft {
  background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 50%;
  background-position: left center;
  transition: all 0.8s linear;
  font-family: Montserrat-Bold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  white-space: nowrap;
  position: absolute;
  left: 60%
}

.navbar-item-market_nft:hover {
  background-position: 100% center;
}

.social-group-logo {
  display: flex;
  align-items: center;
  gap: 12px;
  transition: width 0.4s ease;
  width: 124px;
  overflow: hidden;
}

.social-item {
  width: 100%;
}

.social-item svg {
  cursor: pointer;
  transition: fill 0.4s ease;
}

.social-item:hover stop:nth-child(4) {
  stop-color: #75030B;
  /* Change this to the desired color */
}

.social-item:hover stop:nth-child(3) {
  offset: 33%;
  stop-color: #930210;
  /* Change this to the desired color */
}

.social-item:hover stop:nth-child(2) {
  offset: 67%;
  stop-color: #B4001E;
  /* Change this to the desired color */
}

.social-item:hover stop:nth-child(1) {
  offset: 100%;
  stop-color: #DA291C;
  /* Change this to the desired color */
}



.folow-item svg {
  cursor: pointer;
  transition: 0.3s ease;
}

.folow-item svg:hover {
  fill: #B4001E;
  //transform: scale(1.2);
}

.open-social-items {
  width: 400px;
  //overflow: visible;
}

.social-group-logo-img {
  height: 19px;
  cursor: pointer;
}

.login-button {
  display: flex;
  padding: 18px 44px;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  border: 1px solid #949494;
  background: #FFF;
  color: #949494;
  font-family: Montserrat-Light;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.175px;
  cursor: pointer;
  transition: 0.4s ease;
  position: absolute;
  right: 0;
}

.login-button-connected-web {
  display: flex;
  padding: 18px 44px;
  justify-content: center;
  align-items: center;
  background: #FFF;
  color: #949494;
  font-family: Montserrat-Light;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.175px;
  cursor: pointer;
  transition: 0.4s ease;
  position: absolute;
  right: 0
}

.login-button:hover {
  border: 1px solid #B21815;
  background: #B21815;
  color: #FFF;
}

.digital-art {
  display: flex;
  flex-direction: column;
  width: 1240px;
  margin-top: 161px;
  gap: 35px;
}

.header-side {
  color: #353535;
  font-family: Montserrat-Light;
  font-size: 64px;
  font-style: normal;
  font-weight: 300;
  line-height: 114.5%;
  /* 73.28px */
  z-index: 1;
}

.bottom-side {
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //gap: 107px
}
.mt-36 {
  margin-top: -36px;
}
.bottom-side-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bottom-side-left-header {
  color: #949494;
  font-family: Montserrat-Light;

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  //margin-top: 25px;
}

.bottom-side-left-header_buttons {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
}

.bottom-side-left-header_buttons-airdrop {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
}

.left-button,
.left-button-airdrop {
  display: flex;
  width: 193px;
  height: 59px;
  padding: 24px 44px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 500px;
  color: #FFF;
  font-family: Montserrat-Light;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  /* 17.175px */
  cursor: pointer;
  background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);
  //-webkit-text-fill-color: transparent;
  background-size: 400% 100%;
  background-position: 75% center;
  transition: background 0.3s linear;
}

.left-button:hover {
  background-position: 100% center;
}

.left-button-airdrop:hover {
  background-position: 100% center;
}

.right-button,
.right-button-airdrop {
  display: flex;
  width: 193px;
  height: 59px;
  padding: 24px 44px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 500px;
  border: 1px solid #B21815;
  background: #FFF;
  color: #B21815;
  font-family: Montserrat-Light;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  /* 17.175px */
  cursor: pointer;
  transition: 0.4s ease;

}

.right-button:hover {
  background: #B21815;
  color: #FFF;
}

.right-button-airdrop:hover {
  background: #B21815;
  color: #FFF;
}

.datetime-content {
  width: 170px;
  height: 170px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 2px solid #FFF;
  background: linear-gradient(180deg, #F2F2F2 0%, #CCC 100%);
  box-shadow: 0 4px 13.9px 0 rgba(0, 0, 0, 0.25);
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.datetime-text {
  font-family: Montserrat-Light;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.datetime-number {
  font-family: "Roboto Mono";
  font-size: 104px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.main-component {
  display: flex;
  width: 1240px;
  margin-top: 180px;
  align-items: center;
  justify-content: space-between;
  gap: 59px;
  /*opacity: 0;*/
  transition: all 2s ease;
}

.visible {
  opacity: 1;
  animation: frombottom 1s ease-in-out;
  transition: all 2s ease;
}

.reverse {
  flex-direction: row-reverse;
  gap: 82px
}

.left-side {
  width: 100%;
  z-index: 555;
  //opacity: 0;
  transition: all 2s
}

.right-side {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  //opacity: 0;
  transition: all 2s
}

.lastMainComponent {
  .right-side {
    opacity: 0;
  }

  .left-side {
    opacity: 0;
  }
}

.lastMainComponent1 {
  .right-side {
    opacity: 0;
  }

  .left-side {
    opacity: 0;
  }
}
.lastMainComponent2 {
  .right-side {
    opacity: 0;
  }

  .left-side {
    opacity: 0;
  }
}
.lastMainComponent3 {
  .right-side {
    opacity: 0;
  }

  .left-side {
    opacity: 0;
  }
}

.title-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: max-content;
  z-index: 1;
}

.title-group_line-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px
}

.d-none {
  display: none;
}

.line-width {
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, rgba(195, 16, 29, 0.00) 0%, #75030B 100%);
}

.title {
  font-family: Montserrat-Light;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  /* 22.9px */
  text-transform: uppercase;
  background: linear-gradient(#DA291C, #B4001E, #930210, #75030B);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitle {
  font-family: Montserrat-Bold;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  // background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
  background: linear-gradient(#DA291C, #B4001E, #930210, #75030B);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.content {
  width: 447px;
  color: #949494;
  font-family: Montserrat-Light;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 3.5%;
  //margin-bottom: -20px;
  z-index: 1;
}

.main-component-button {
  width: 100%;
  display: flex;
  padding: 24px 44px;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  color: #FFF;
  leading-trim: both;
  text-edge: cap;
  font-family: Montserrat-ExtraLight;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  /* 17.175px */
  cursor: pointer;
  background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);
  /*-webkit-text-fill-color: transparent;*/
  background-size: 400% 100%;
  background-position: 75% center;
  transition: background 0.3s linear;
}

.security-component-button {
  display: flex;
  width: 181px;
  padding: 24px 44px;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);
  color: #FFF;
  font-family: Montserrat-Light;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  /* 17.175px */
  cursor: pointer;
  background-size: 400% 100%;
  background-position: 75% center;
  transition: background 0.3s linear;
}

.security-component-button:hover {
  background-position: 100% center;
}

.main-component-button_width {
  width: 193px;
}

.main-component-button:hover {
  background-position: 100% center;
}

.input-group {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-top: -20px;
  margin-bottom: 20px;
  width: 100%;
}

.input {
  display: flex;
  width: 100%;
  padding: 17px 69px 17px 24px;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  border: unset;
  background: #FFF;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  color: #949494;
  font-family: Montserrat-Light;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  /* 17.175px */
}

.input:focus-visible {
  outline: none;
}

/*.security {*/
/*  display: flex;*/
/*  !*align-items: center;*!*/
/*  gap: 25px;*/
/*}*/

.security-item {
  width: 291px;
  height: 280px;
  position: relative;
  border-radius: 25px;
  filter: drop-shadow(0px 4px 2.4px rgba(0, 0, 0, 0.13));
  background: url("./assets/bg-mask-group.png") lightgray 50% / cover no-repeat;
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  //justify-content: center;
  cursor: pointer;
  transform: perspective(300px) rotateX(0deg) rotateY(0deg);
  transition: .2s;
}

.new-class {
  perspective: 4800px;
  rotateX: 20deg;
  rotateY: 15deg
}

/* .security-item:hover{
  transform:  rotateX(20deg) rotateY(15deg)
} */

.security-item-content {
  width: 100%;
  max-width: 236px;
  color: #949494;
  font-family: Montserrat-Light;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.security-item-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  /*leading-trim: both;*/
  /*text-edge: cap;*/
  font-family: Montserrat-Bold;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.security-item-title img {
  width: 56px;
  height: 64px;
}

.security-group {
  //display: flex;
  //flex-direction: row;
  //align-items: center;
  //justify-content: space-between;
  //width: 1240px;
  //gap: 25px;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 1240px;
  height: 300px;
  gap: 25px;
  display: -webkit-inline-box;
  margin-left: 22px !important;
  overflow-x: auto !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  scroll-behavior: smooth !important;
  flex-wrap: unset !important;
}

.security-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 160px;
  gap: 48px;
  /*background: #FFFFFF;*/
}

.social-big-logo-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 160px;
  gap: 48px;
  /*background: #FFFFFF;*/
}

.security-component-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 17px
}

.line_1 {
  width: 80px;
  height: 1px;
  background: linear-gradient(90deg, rgba(195, 16, 29, 0.00) 0%, #75030B 100%);
}

.security-component-title {
  leading-trim: both;
  text-edge: cap;
  font-family: 'Montserrat-Light';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  /* 22.9px */
  text-transform: uppercase;
  background: linear-gradient(#75030B, #930210, #B4001E, #DA291C);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.roadmap {
  position: relative;
  width: 1240px;
  height: 444px;
  margin-top: 41px;
}

.roadmapBlocLine {
  width: 761px;
  height: 1px;
  background: linear-gradient(#75030B, #930210, #B4001E, #DA291C);
  margin-top: 51px;
}

.roadmapCircle {
  position: absolute;
  top: 28.5%;
  left: 48px;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s linear;

}

.line_vertical {
  width: 1px;
  height: 321px;
  background: linear-gradient(#75030B, #930210, #B4001E, #DA291C);
  position: absolute;
  left: 760px;
}

.roadmapCircleVertical {
  position: absolute;
  left: -6px;
  top: 18px;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s linear;

}

.roadmapTitle {
  font-family: Montserrat-Bold;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: linear-gradient(#DA291C, #B4001E, #930210, #75030B);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
  left: 41px;
  top: -8px;
}

.smTitleRoadmap {
  background: linear-gradient(#DA291C, #B4001E, #930210, #75030B);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Montserrat-Light;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.roadmapCircleVerticalSecondToThird {
  position: absolute;
  left: 754px;
  bottom: 27%;
}

.line_third_to_fifth {
  width: 45px;
  height: 1px;
  background: linear-gradient(#DA291C, #B4001E, #930210, #75030B);
  position: absolute;
  bottom: 40px;
  right: 291px;
}

.roadmapCircleVerticalThirdToFifth {
  position: absolute;
  right: 144.6px;
  bottom: 140px;
  z-index: 10;
}

.roadmapCircleVerticalFifth {
  position: absolute;
  bottom: 20px;
  right: 144.6px;
  top: 90px;
}

.line_fifth_to_fourth {
  width: 1px;
  height: 385px;
  position: absolute;
  right: 150px;
  background: linear-gradient(#DA291C, #B4001E, #930210, #75030B);
  bottom: 0px;
}

.line_next {
  position: absolute;
  right: 4px;
  top: 54px;
}

.right_arrow {
  // background: linear-gradient(#DA291C, #B4001E, #930210, #75030B);
  // position: absolute;
  // right: 5px;
  // top: 49px;
  width: 146px;
  height: 0px;
  flex-shrink: 0;
  stroke-width: 1px;
  stroke: #75030B;
}

.roadmapNextBtn {
  font-family: Montserrat-Light;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  /* 22.9px */
  text-transform: uppercase;
  background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
  right: 4px;
  top: 24px;
  cursor: pointer;
}

.roadmapPrevBtn {
  font-family: Montserrat-Light;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  /* 22.9px */
  text-transform: uppercase;
  background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
  right: 64px;
  top: 24px;
  cursor: pointer;
}

.roadmapFirstBlock {
  width: 587px;
  height: 274px;
  flex-shrink: 0;
  border-radius: 25px;
  background: #FFF;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  // background: blue;
  margin-top: 51px;
  position: absolute;
  background-image: url(./assets/roadmap-dot.svg);
  background-repeat: no-repeat;
  background-position: right;
  transition: all .8s linear;
}

.roadmapSecondBlock {
  width: 271px;
  height: 99px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #FFF;

  /* 1 */
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  position: absolute;
  left: 632px;
  bottom: 165px;
  transition: all 1s linear;

}

.roadmapThirdlock {
  width: 271px;
  height: 99px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #FFF;

  /* 1 */
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  position: absolute;
  left: 632px;
  bottom: -12px;
  transition: all 1s linear;

}

.roadmapFourthBlock {
  width: 291px;
  height: 99px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #FFF;

  /* 1 */
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  position: absolute;
  right: 0;
  top: 135px;
  transition: all 1s linear;
}

.roadmapFifthBlock {
  width: 291px;
  height: 99px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #FFF;

  /* 1 */
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  position: absolute;
  right: 0;
  bottom: -12px;
  transition: all 1s linear;

}

.open {
  width: 100vw;
  // position: absolute;
}


.social-group-component {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  //padding: 0 140px;
  max-width: 891px;
  width: 100%;
  gap: 50px;
  flex-wrap: wrap;
}

.social-group-component1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  //padding: 0 140px;
  //width: 1240px;
  //background: greenyellow;
  gap: 70px;
  flex-wrap: wrap;
  margin-left: 75px;
}

.folow-item:hover {
  stop:nth-child(4) {
    stop-color: #75030B;
    /* Change this to the desired color */
  }

  stop:nth-child(3) {
    offset: 33%;
    stop-color: #930210;
    /* Change this to the desired color */
  }

  stop:nth-child(2) {
    offset: 67%;
    stop-color: #B4001E;
    /* Change this to the desired color */
  }

  stop:nth-child(1) {
    offset: 100%;
    stop-color: #DA291C;
    /* Change this to the desired color */
  }
}


.footer {
  display: flex;
  flex-direction: column;
  margin-top: 139px;
  width: 1240px;
}

.footer-top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 48px;
  border-bottom: 1px solid #DFDFDF;
}

.footer-top-left {
  display: flex;
  flex-direction: column;
  gap: 28px;

}

.footer-top-left-text {
  max-width: 172px;
  color: #949494;
  leading-trim: both;
  text-edge: cap;
  font-family: Montserrat-Light;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  /* 16.03px */
  text-transform: uppercase;
}

.footer-top-right {
  display: flex;
  gap: 87px;
}

.nav-footer {
  display: flex;
  width: 290px;
  flex-wrap: wrap;
  gap: 20px 87px;
  justify-content: space-between;
}

.nav-footer-item {
  font-family: Montserrat-Light;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  color: #949494;
  background-position: left center;
  transition: background 0.3s linear;
  white-space: nowrap;
  width: 98px;
  display: flex;
  justify-content: flex-end;
}

.nav-footer-item:hover {
  background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.nav-footer-item-market {
  font-family: Montserrat-Light;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  color: #949494;
  background-position: left center;
  transition: background 0.3s linear;
  white-space: nowrap;
  width: 98px;
  display: flex;
  justify-content: flex-end;
}


.footer-bottom {
  display: flex;
  padding-top: 28px;
  padding-bottom: 103px;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.footer-bottom-left {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.footer-bottom-left-mobile {
  margin-top: 60px;
}

.footer-bottom-left-item {
  color: #949494;
  leading-trim: both;
  text-edge: cap;
  font-family: Montserrat-Light;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  /* 18.32px */
  text-transform: uppercase;
}

.footer-bottom-right-group {
  display: flex;
  gap: 16px;
  align-items: center;
}

.footer-bottom-right-group-mob {
  position: absolute;
  bottom: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* width: 29%; */
  /* padding: 0 200px; */
  gap: 24px;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  /*background: green;*/
  width: 100%;
}

.footer-bottom-right {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 162px;
  overflow: hidden;
  transition: width 0.5s ease;
}

.footer-bottom-right img {
  cursor: pointer;
}

.open-icons-group {
  width: 427px
}

.bg-image {
  position: absolute;
  /*z-index: 0;*/
  top: 135px;
  right: 0;
}

/*Mobile Header*/

:where(.css-dev-only-do-not-override-1okl62o).ant-drawer-right>.ant-drawer-content-wrapper {
  top: 65px;
}

:where(.css-dev-only-do-not-override-1okl62o).ant-drawer .ant-drawer-header {
  display: none;
}

/*:where(.css-dev-only-do-not-override-1okl62o).ant-drawer .ant-drawer-mask {*/
/*  top: 95px;*/
/*}*/
.drower-content-navbar {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-bottom: 1px solid #DFDFDF;
  ;
  height: calc(100% - 95px);
  overflow: scroll;
}

.drower-content-profile {
  align-items: flex-start;
  cursor: pointer;
}

.drower-content-navbar-item {
  color: #949494;
  font-family: Montserrat-Light;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.drawer-content-navbar-nft {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-top: 16px;
  font-family: Montserrat-Light;
  //width: 130px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.drawer-content-navbar-bottom {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 48px 0 0;
  height: 84px;
  gap: 24px;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.mobile-header {
  max-width: 770px;
  width: 100%;
  height: 76px;
  background: #FFF;
  /*box-shadow: 0px 4px 4px 0px rgba(188, 188, 188, 0.25);*/
  padding: 0 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 5000;
}

.login-burger-group {
  display: flex;
  align-items: center;
  gap: 12px;
}

.hamburger {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.line {
  width: 24px;
  height: 2px;
  background: #949494;
  margin: 4px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.is-active {
  cursor: pointer;
}

#hamburger-1.is-active .line:nth-child(2) {
  opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1) {
  -webkit-transform: translateY(6px) rotate(45deg);
  -ms-transform: translateY(6px) rotate(45deg);
  -o-transform: translateY(6px) rotate(45deg);
  transform: translateY(6px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-6px) rotate(-45deg);
  -ms-transform: translateY(-6px) rotate(-45deg);
  -o-transform: translateY(-6px) rotate(-45deg);
  transform: translateY(-6px) rotate(-45deg);
}

.mobile-login-button {
  display: flex;
  padding: 16px 40px;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  border: 1px solid #949494;
  background: #FFF;
  color: #949494;
  font-family: Montserrat-Light;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  /* 13.74px */
  cursor: pointer;
}

.walf-image-web {
  img {
    width: 571px;
    border-radius: 26px 0 0 26px;
  }
}

@media screen and (max-width: 1300px) {
  .investors {
    margin-top: 35px !important;
  }
  .contact-text {
    //height: 48px!important;
    width: 770px!important;
    //font-size: 22px!important;
  }
  .forinvestors-text {
      height: 48px!important;
      width: 770px!important;
      font-size: 22px!important;
  }
  .investors-first-item {
    margin-top: -43px!important;
  }

  .contacts {
    margin-top: 36px !important;
  }

  .market {
    margin-top: 35px !important;
  }

  //.container-slider1 {
  //  width: 100% !important;
  //  gap: 16px !important;
  //  //background: red;
  //  margin-left: 22px;
  //  overflow-x: scroll !important;
  //  -ms-overflow-style: none !important;
  //  scrollbar-width: none !important;
  //  scroll-behavior: smooth !important;
  //  display: flex !important;
  //  align-items: center !important;
  //  justify-content: flex-start !important;
  //  flex-wrap: nowrap !important;
  //  //height: 335px!important;
  //  padding-right: 22px !important;
  //}

  //.slick-slider {
  //  gap: 16px!important;
  //  //background: red;
  //  width: 100%!important;
  //  margin-left: 22px;
  //  overflow-x: scroll!important;
  //  -ms-overflow-style: none!important;
  //  scrollbar-width: none!important;
  //  scroll-behavior: smooth!important;
  //  display: flex!important;
  //  align-items: center!important;
  //  justify-content: center!important;
  //  flex-wrap: nowrap!important;
  //  //height: 335px!important;
  //  padding-right: 22px!important;
  //}

  //.slick-slider {
  //  height: 300px !important;
  //}

  .market-buttons-group {
    overflow-x: scroll !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    scroll-behavior: smooth !important;
    width: 100% !important;
    padding-left: 22px !important;
    padding-right: 22px !important;
    margin-top: 48px !important;
  }

  .market-button {
    padding: 12px 16px !important;
  }

  .button-is-active {
    padding: 12px 16px !important;
  }

  .nft-group {
    gap: 16px !important;
    //background: red;
    width: 100% !important;
    margin-left: 22px;
    overflow-x: scroll !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    scroll-behavior: smooth !important;
    display: flex !important;
    align-items: center !important;
    flex-wrap: nowrap !important;
    height: 335px !important;
    padding-right: 42px !important;
    margin-top: -34px!important;
  }
  .nft-group-mobile {
    gap: 16px !important;
    //background: red;
    width: 100% !important;
    margin-left: 22px;
    overflow-x: scroll !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    scroll-behavior: smooth !important;
    display: flex !important;
    align-items: center !important;
    flex-wrap: nowrap !important;
    height: 335px !important;
    padding-right: 42px !important;
    margin-top: -34px!important;
  }

  .nft-item {
    width: 227px !important;
    height: 322px !important;
    padding: 12px 10px !important;
    gap: 4px !important;
  }

  .nft-item .nft-item-picture img {
    height: 220px !important;
  }

  .nft-item_details__title-group__title {
    font-size: 12px !important;
  }

  .nft-item_details {
    gap: 0 !important;
    padding-top: 4px !important;
  }

  .nft-item_details__content___item____top {
    font-size: 12px !important;
  }

  .make-offer-button {
    width: 199px !important;
    height: 42px !important;
    margin-top: 20px !important;
  }

  .nft-item:hover .nft-item_details {
    transform: translateY(-55px) !important;
  }

  .nft-item:hover {
    transform: translateY(-7px) !important;
  }

  .mobile-header {
    padding: 0 !important;
  }

  .market-block1 {
    width: 100% !important;
  }

  .social-big-logo-group {
    gap: 36px;
  }

  .discover {
    margin-top: 36px !important;
  }

  .bottom-side {
    display: contents;
  }

  .bottom-side-left {
    gap: 44px;
    margin-bottom: 44px;
    z-index: 5;
  }

  .digital-art {
    width: auto;
  }

  .web-header {
    display: none;
  }

  .main-component {
    display: block;
    width: auto;
    margin-top: 200px;
  }

  .bg-image {
    top: 5px;
    right: 0;
  }

  .footer {
    width: 770px;
  }

  .security-component {
    width: 100%;
  }

  .security-item {
    width: 248px;
    height: 230px;
    gap: 12px
  }

  .security-group {
    justify-content: center;
    //width: 970px;
    gap: 18px;
  }

  .discover-security-group {
    justify-content: center;
    width: 970px;
    height: 342px;
    gap: 18px;
  }

  .security-item-title img {
    width: 64px;
  }

  .about-text {
    width: 720px !important;
  }

  .about-text-content {
    margin-top: -75px !important;
  }
  .discover-text {
    height: 48px!important;
    width: 770px!important;
    font-size: 22px!important;
  }

  .discover-first-item {
    width: 770px !important;
    height: 407px !important;
    margin-top: -48px!important;
  }

  .contacts-first-item {
    width: 770px !important;
    height: 407px !important;
  }

  .investors-first-item {
    width: 770px !important;
    height: 407px !important;
  }

  .discover-header-side {
    color: #353535 !important;
    font-size: 32px !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 114.5% !important;
  }

  .discover-bottom-side-left {
    gap: 32px !important;
  }

  .discover-digital-art {
    margin-top: 21px !important;
    margin-left: 31px !important;
  }

  .discover-digital-art-contacts {
    margin-top: 80px !important;
    margin-left: 38px !important;
  }

  .discover-bottom-side-left-header_buttons {
    justify-content: space-between;
  }

  .discover-right-button {
    padding: 18px 32px !important;
    min-width: 150px !important;
  }

  .discover-left-button {
    padding: 18px 32px !important;
  }

  .discover-security-group {
    gap: 16px !important;
    width: 100% !important;
    margin-left: 22px !important;
    overflow-x: auto !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    scroll-behavior: smooth !important;
    flex-wrap: unset !important;
  }

  .discover-last-item {
    width: 770px !important;
  }

  .walf-image-web {
    img {
      width: 300px !important;
    }
  }

  .offer-block {
    width: 100% !important;
  }

  .offer-group {
    gap: 0px !important;
    width: 100% !important;
    //margin-left: 22px!important;
    overflow-x: auto !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    scroll-behavior: smooth !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    flex-wrap: nowrap !important;
  }

  .offer {
    width: 291px !important;
    height: 262px !important;
    padding-left: 28px;
    padding-right: 24px;
    background-image: url("./assets/offer-mobile.svg") !important;

    &__content {
      margin-top: 97px;

      &__title {
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: normal !important;
      }

      &__text {
        font-size: 12px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
      }
    }

  }

  .telephone,
  .email,
  .address,
  .social {
    width: 230px !important;
    height: 225px !important;
  }

  .actions-group {
    width: unset !important;
  }

  .responsive-main-top {
    margin-top: -490px !important;
  }

  .airdrop {
    margin-top: 35px !important;
  }

  .airdrop-first-item {
    height: 100% !important;
    padding-bottom: 20px !important;
  }

  .airdrop-nft-left {
    gap: 16px !important;
  }

  .nft-details {
    gap: 12px !important;
  }

  .airdrop-last-item {
    margin-top: 20px !important;
    width: 822px !important;
  }

  .airdrop-text {
    span {
      width: 770px !important;
    }
  }
  .responsive-position {
    margin-top: -135px!important;
  }
}

//end  1300px
.nft-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 1240px;
  gap: 22px;
}
.nft-group-mobile {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 1240px;
  gap: 22px;
}

.responsive-main-top {
  margin-top: -200px;
}

@media screen and (min-width: 1300px) {
  .mobile-header {
    display: none;
  }
  .nft-group-mobile {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .bg-image-web {
    display: none;
  }

  .airdrop-countdown-small {
    width: auto !important;
  }

  .right-button-airdrop,
  .left-button-airdrop {
    width: 146px;
    height: 50px;
  }

  .nft-group {
    justify-content: flex-start !important;
  }
  .nft-group-mobile {
    justify-content: flex-start !important;
  }

  .investors {
    margin-top: 60px !important;
  }

  //.roadmap2 {
  //  margin-top: -60px !important;
  //}

  .actions-group {
    flex-wrap: wrap !important;
    width: 390px !important;
    justify-content: center !important;
    margin-top: 70px !important;
  }
  .forinvestors-text-block {
    display: none!important;
  }


}

@media screen and (min-width: 1023px) {
  .bg-image-mobile {
    display: none;
  }

  .left-side-image-mob {
    display: none;
  }

  .footer-logo-mob {
    display: none;
  }

  .footer-bottom-right-group-mob {
    display: none;
  }

  .discover-first-item-mobile {
    display: none;
  }

  .investors-first-item-mobile {
    display: none;
  }
}

@media screen and (max-height: 480px) {
  .drower-content-navbar {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 22px;
    border-bottom: 1px solid #DFDFDF;
    height: calc(100% - 95px);
    overflow: scroll;
  }

  .drawer-content-navbar-nft {
    margin-bottom: 26px;
  }
}

@media screen and (max-width: 1023px) {
  .auth-countdown {
    margin-top: -208px;
  }

  .svgbig-container {
    width: 70px !important;
    height: 29px !important;
  }

  .social-group-component1 {
    gap: 17px !important;
    margin-left: 20px !important;
    margin-top: -20px !important;
  }

  .folow-itembig {
    width: 76px;
  }

  .auth-layouth {
    gap: 55px;
  }

  .mobile-header {
    padding: 0 22px !important;
  }

  .header-side {
    font-size: 32px;
    font-weight: 300;
    line-height: 114.5%;
  }

  .bottom-side-left {
    position: relative;
    gap: 28px;
  }

  .bottom-side-left-header {
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    margin-top: 28px;
  }

  .left-button {
    width: 180px;
    padding: 20px 42px;
    gap: 10px;
    flex-shrink: 0;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 114.5%;
    /* 16.03px */
  }

  .right-button {
    width: 180px;
    padding: 20px 42px;
    gap: 10px;
    flex-shrink: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 114.5%;
  }

  .bottom-side-left-header_buttons {
    gap: 10px;
    margin-top: 105px;
  }

  .digital-art {
    gap: 0
  }

  .datetime-content {
    width: 80px;
    height: 80px;
  }

  .datetime-number {
    font-size: 44px;
  }

  .datetime-text {
    font-size: 8px;
  }

  .digital-art {
    margin-top: 410px;
  }

  .left-side-image-web {
    display: none;
  }

  .discover {
    margin-top: 54px !important;
  }

  .left-side {
    text-align: center;
  }

  .title {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 114.5%;
    /* 16.03px */
    text-transform: uppercase;
  }

  .subtitle {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

  }

  .content {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 350px;

  }

  .main-component-2 {
    margin-top: 120px !important;
    gap: 28px !important;
  }

  .main-component-2__left-side {
    gap: 28px !important;
  }

  .main-component-2-subtitle {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
  }

  .main-component-2-content {
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
  }

  .main-component-button {
    margin-top: 35px;
    padding: 20px 42px;
  }

  .right-side {
    align-items: center;
  }

  .title-group {
    gap: 2px;
    margin-left: -210px;
  }

  .input-group {
    align-items: center;
    margin-bottom: -24px;
  }

  .security-component-title {
    font-size: 16px;
    font-style: normal;
    font-family: "Montserrat-Light";
    font-weight: 400;
    line-height: 114.5%;
    /* 18.32px */
    text-transform: uppercase;
  }

  .folow-item svg {
    width: 75px;
    height: 30px;
  }

  .social-group-component {
    gap: 17px;
    max-width: 415px;
  }

  .security-component-header {
    gap: 8px
  }

  .social-big-logo-group {
    margin-top: 73px;
    gap: 33px;
    margin-bottom: -114px;
  }

  .main-component {
    margin-top: 133px;
  }

  .right-button:hover {
    background: #FFF;
    color: #B21815;
  }

  .security-component {
    margin-top: 73px;
    gap: 25px
  }

  .security-component-button {
    margin-top: 30px;
  }

  //.security-item-title {
  //  gap: 12px
  //}
  //
  //.security-item-title img {
  //  width: 54px;
  //  height: 64px;
  //}
  //
  //.security-item-subtitle {
  //  font-size: 16px;
  //  font-style: normal;
  //  font-weight: 600;
  //  line-height: normal;
  //}
  //
  //.security-item-content {
  //  font-size: 12px;
  //  font-style: normal;
  //  font-weight: 400;
  //  line-height: normal;
  //  width: 291px;
  //}
  //
  //.security-item {
  //  gap: 20px;
  //  width: 291px;
  //  height: 237px;
  //  padding: 0px 21px 0 18px
  //}

  //.security-group {
  //  gap: 16px;
  //  /*background: red;*/
  //  width: 100%;
  //  margin-left: 22px;
  //  /*margin-right: 52px;*/
  //  overflow-x: auto;
  //  -ms-overflow-style: none;
  //  scrollbar-width: none;
  //  //scroll-behavior: smooth;
  //  display: flex;
  //  align-items: center;
  //  justify-content: flex-start;
  //}

  .footer-logo-web {
    display: none;
  }

  .footer-top-left {
    gap: 16px
  }

  .footer-top-left-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 114.5%;
    /* 13.74px */
    text-transform: uppercase;
  }

  .footer-top {
    display: block;
    padding-bottom: 32px;
  }

  .footer-top-right {
    margin-top: 48px;
    gap: 24px;
  }

  .nav-footer {
    display: flex;
    width: 350px;
    gap: 12px 32px;
  }

  .footer-bottom {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 20px;
    padding-bottom: 32px;
    align-items: flex-start;
    /*justify-content: flex-end;*/
    gap: 24px;
    flex-wrap: wrap;
    width: 100%;
  }

  .footer-bottom-left-item {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 114.5%;
    /* 13.74px */
  }

  .footer {
    width: auto;
    margin-top: 70px;
  }

  .mobile-header {
    width: 370px !important;
    padding: 0 10px !important;
    height: 70px !important;
  }

  .logo img {
    width: 136px;
    height: 38px;
  }

  .mobile-login-button {
    padding: 18px 44px;
    width: 90px;
    height: 32px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .footer-bottom-right-group {
    display: none;
  }

  .flip-card {
    width: 80px !important;
  }

  .datetime-content {
    width: 80px !important;
    height: 80px !important;
  }

  .top,
  .bottom,
  .flip-card.flip::after,
  .flip-card.flip::before {
    width: 76px !important;
    font-size: 48px;
  }

  .card-title {
    font-size: 8px !important;
    top: 6px !important;
  }

  .countdown-container {
    gap: 10px !important;
  }

  .about-text {
    width: 345px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
  }

  .about-text-content {
    margin-bottom: -170px !important;
    height: 43px !important;
    margin-top: -65px !important;
  }

  .about {
    margin-top: 95px !important;
    gap: 65px !important;
  }

  .discover-first-item {
    display: none!important;
  }
  .discover-text-block {
    display: none!important;
  }

  .investors-first-item {
    display: none!important;
  }

  .discover-first-item-mobile {
    //display: flex;
    //align-items: center;
    //justify-content: center;
    background-image: url("./assets/discover-bg-mobile.svg");
    //width: 1240px;
    //height: 776px;
    margin-top: 0px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .investors-first-item-mobile {
    //display: flex;
    //align-items: center;
    //justify-content: center;
    background-image: url("./assets/investors-mobile-bg.svg");
    //width: 1240px;
    //height: 776px;
    margin-top: 0px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .discover-text {
    color: #D7D7D7 !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    height: 43px !important;
    background: #FFFFFF !important;
    align-items: flex-start !important;
    width: 100%!important;
  }

  .discover-last-item {
    width: 350px !important;
    flex-direction: column-reverse;
    padding: 0 !important;
  }

  .contact-text {
    font-size: 16px!important;
    font-style: normal!important;
    font-weight: 400!important;
    height: 43px !important;
    width: 353px!important;
    margin-bottom: -14px!important;

  }
  .contact-text-block {
    height: 43px !important;
    margin-top: -7px!important;
    //background: #FFFFFF!important;
  }

  .airdrop-last-item {
    width: 350px !important;
    flex-direction: column-reverse;
    padding: 0 !important;
  }

  .walf-image-web {
    img {
      width: 350px !important;
      border-radius: 26px 26px 0 0;
    }
  }

  .discover-header-side {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 114.5% !important;
  }

  .discover-last-item-digital-art {
    width: 100% !important;
    gap: 25px !important;
    padding: 38px 0 56px 24px;
  }

  .discover-bottom-side-left-header {
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
  }

  .contacts-text {
    color: #D7D7D7 !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    height: 43px !important;
    background: #FFFFFF !important;
    align-items: flex-start !important;
  }

  .contacts-first-item {
    width: 370px !important;
    margin-top: -10px;
  }

  .discover-digital-art {
    width: 100% !important;
    margin-top: 379px !important;
    margin-left: 12px !important;
  }

  .discover-digital-art-contacts {
    width: 100% !important;
    margin-top: 379px !important;
    margin-left: 0px !important;
  }

  .discover-security-item {
    min-width: 291px !important;
    height: 237px !important;
    gap: 24px !important;
  }

  .discover-security-item-title {
    gap: 12px !important;
  }

  .discover-security-item-content {
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
  }

  .name {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 114.5% !important;
  }

  .nft-details .details .creator-name {
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
  }

  .nft-details .details .price {
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 60 !important;
    line-height: normal !important;
  }

  .nft-details {
    gap: 16px !important;
  }

  .airdrop-nft-left {
    gap: 24px !important;
    padding: 16px 16px 24px 16px !important;
    //background: greenyellow;
    width: 100%;
  }

  .airdrop-text {
    padding: 12px !important;
    height: 49px !important;
    background: #ffffff !important;

    span {
      width: 370px !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
      padding: 0 12px !important;
    }
  }

  .airdrop {
    margin-top: 36px !important;
  }

}

.anim-from-left {
  animation: fromleft 1.1s ease-in-out;
  animation-fill-mode: both;
}

@keyframes fromleft {
  0% {
    transform: translate(-124px);
    opacity: 0;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

.anim-from-zero-to-right {
  animation: fromZeroToRight 1.1s ease-in-out;
  animation-fill-mode: both;
}

@keyframes fromZeroToRight {
  0% {
    transform: translate(0);
    opacity: 1;
  }

  100% {
    transform: translate(124px);
    opacity: 0;
  }
}

.anim-from-right {
  animation: fromright 1.1s ease-in-out;
  animation-fill-mode: both;
}

@keyframes fromright {
  0% {
    transform: translate(124px);
    opacity: 0;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}
.anim-from-zero-to-left {
  animation: fromZeroToLeft 1.1s ease-in-out;
  animation-fill-mode: both;
}

@keyframes fromZeroToLeft {
  0% {
    transform: translate(0);
    opacity: 1;
  }

  100% {
    transform: translate(-124px);
    opacity: 0;
  }
}
@keyframes frombottom {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.anim-from-top {
  animation: fromTop 2s ease-in-out;
  animation-fill-mode: backwards;
}

@keyframes fromTop {
  0% {
    transform: translateY(-750px);
    opacity: 0;
  }

  20% {
    opacity: .1;
  }

  30% {
    opacity: .2;
  }

  40% {
    opacity: .3;
  }

  50% {
    opacity: .4;
  }

  60% {
    opacity: .5;
  }

  70% {
    opacity: .6;
  }

  80% {
    opacity: .7;
  }

  90% {
    opacity: .8;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fromBottomQuiq {
  0% {
    transform: translateY(350px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

//#firstMainComponent {
//  animation: fromTopEasy 2s ease-in-out;
//  animation-fill-mode: backwards;
//}
@keyframes fromTopEasy {
  0% {
    transform: translateY(950px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

//CountDown
.auth-countdown {
  display: flex;
  align-items: center;
  justify-content: center;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.countdown-aniamtion {
  //background-color: #f2f1ed;
  color: #de4848;
  //padding: 20px;
  //font-size: clamp(2em,4.5vw,5em);
  //height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  //width: 100%;
  font-family: "Roboto Mono";
  font-size: 104px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.flip-card {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  border-radius: 20px;
  border: 2px solid #FFF;
  //background: linear-gradient(180deg, #F2F2F2 0%, #CCC 100%);
  box-shadow: 0px 4px 13.9px 0px rgba(0, 0, 0, 0.25);
  width: 170px;
  align-items: center;
}

.top,
.bottom,
.flip-card.flip::after,
.flip-card.flip::before {
  padding: 0.25em;
  height: 0.75em;
  line-height: 1;
  overflow: hidden;
  width: 166px;
  //text-align: center;

}

.top,
.flip-card.flip::before {
  //height: 85px;
  border-radius: 20px 20px 0px 0px;
  background: linear-gradient(180deg, #FAFAFA 0%, #E2E2E2 100%);
  display: flex;
  justify-content: center;
}

.bottom,
.flip-card.flip::after {
  display: flex;
  align-items: flex-end;
  border-radius: 0px 0px 20px 20px;
  background: linear-gradient(180deg, #F2F2F2 0%, #CCC 100%);
  justify-content: center;
  border-top: 1px solid #FFFFFF;
}

.flip-card.flip::before {
  top: 0;
  left: 0;
  width: 100%;
  content: attr(data-current-number);
  position: absolute;
  transform-origin: bottom;
  animation: flip-bottom 500ms ease;
  backface-visibility: hidden;
}

@keyframes flip-bottom {
  100% {
    transform: rotateX(180deg);
  }
}

.flip-card.flip::after {
  left: 0;
  bottom: 0;
  width: 100%;
  content: attr(data-next-number);
  position: absolute;
  transform-origin: top;
  animation: flip-top 500ms ease;
  transform: rotateX(180deg);
  backface-visibility: hidden;
}

@keyframes flip-top {
  100% {
    transform: rotateX(0deg);
  }
}

.countdown-container {
  display: flex;
  gap: 21px;
}

.countdown-cards {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.card-title {
  font-family: Montserrat-Light;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 16px;
  text-transform: uppercase;
  position: absolute;
  top: 12px;
  z-index: 5;

}

.card-container {
  display: flex;
  gap: 0.125em;
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 88px;

  .error-404 {
    background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);

    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-family: "Futura PT";
    font-size: 200px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .error-text {
    color: #0C100E;
    font-family: "Futura PT";
    font-size: 36px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    margin-bottom: 26px;
    background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);

    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

//About
.about {
  width: 100%;
  display: flex;
  /*justify-content: center;*/
  flex-direction: column;
  align-items: center;
  margin-top: 110px;
  //background:red;
}

.about-text-content {
  width: 100%;
  background: linear-gradient(270deg, #FFF 0%, #F7F7F7 75%);
  margin-bottom: -160px;
  display: flex;
  justify-content: center;
  height: 70px;
  align-items: center;
  margin-top: -56px;
}

.about-text {
  height: 100%;
  align-items: center;
  display: flex;
  width: 1240px;
  color: #D7D7D7;
  font-family: Montserrat-Light;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

//Discover

.discover-first-item {
  //display: flex;
  //align-items: center;
  //justify-content: center;
  background-image: url("./assets/discover-bg.svg");
  width: 1240px;
  height: 660px;
  margin-top: -72px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 0 0 26px 26px;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  display: flex;
  align-items: center;
}

.discover {
  width: 100%;
  display: flex;
  /*justify-content: center;*/
  flex-direction: column;
  align-items: center;
  margin-top: 53px;
  //background:red;
  //position: relative;
}

.discover-text-block {
  width: 100%;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, #F7F7F7 100%);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.contact-text-block {
  width: 100%;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, #F7F7F7 100%);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  //background: greenyellow;
}
.contact-text {
  justify-content: flex-start;
  width: 1240px;
  color: #D7D7D7;
  font-family: Montserrat-Light;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  height: 70px;
  display: flex;
  align-items: center;
  //background: red;
}

.discover-text {
  justify-content: flex-start;
  width: 1240px;
  color: #D7D7D7;
  font-family: Montserrat-Light;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  height: 70px;
  display: flex;
  align-items: center;
  //background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, #FAFAF7 100%);
  //backdrop-filter: blur(2px);
}



.discover-digital-art {
  display: flex;
  flex-direction: column;
  width: 50%;
  //margin-top: 125px;
  gap: 32px;
  margin-left: 90px;
}

.discover-digital-art-contacts {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 125px;
  gap: 32px;
  margin-left: 90px;
}

.discover-header-side {
  color: #353535;
  font-family: Montserrat-Light;

  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 114.5%;
  /* 45.8px */
  z-index: 1;
}

.discover-bottom-side {
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 107px
}

.discover-bottom-side-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 63px
}

.discover-bottom-side-left-header {
  color: #949494;
  font-family: Montserrat-Light;

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.discover-bottom-side-left-header_buttons {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
}

.discover-left-button {
  display: flex;
  /*min-width: 100px;*/
  padding: 24px 44px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 500px;
  color: #FFF;
  font-family: Montserrat-ExtraLight;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  /* 17.175px */
  cursor: pointer;
  background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);

  //-webkit-text-fill-color: transparent;
  background-size: 400% 100%;
  background-position: 75% center;
  transition: background 0.3s linear;
}

.discover-left-button:hover {
  background-position: 100% center;
}

.discover-last-item-left-button {
  display: flex;
  width: 147px;
  height: 59px;
  padding: 24px 44px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 500px;
  color: #FFF;
  font-family: Montserrat-Light;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  /* 17.175px */
  cursor: pointer;
  background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);

  //-webkit-text-fill-color: transparent;
  background-size: 400% 100%;
  background-position: 75% center;
  transition: background 0.3s linear;
}

.discover-last-item-left-button:hover {
  background-position: 100% center;
}

.discover-last-item-digital-art {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 32px;
}

.discover-right-button {
  display: flex;
  min-width: 181px;
  padding: 24px 44px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 500px;
  border: 1px solid #B21815;
  background: #FFF;
  color: #B21815;
  font-family: Montserrat-Light;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  /* 17.175px */
  cursor: pointer;
  transition: 0.4s ease;
}

.discover-right-button:hover {
  background: #B21815;
  color: #FFF;
}

.discover-security-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 1240px;
  gap: 25px;
  flex-wrap: wrap;
}

.discover-security-item {
  min-width: 396px;
  height: 320px;
  position: relative;
  border-radius: 25px;
  filter: drop-shadow(0px 4px 2.4px rgba(0, 0, 0, 0.13));
  background: url("./assets/bg-mask-group.png") lightgray 50% / cover no-repeat;
  padding: 0 31px 0 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  cursor: pointer;
  transform: perspective(300px) rotateX(0deg) rotateY(0deg);
  transition: .2s;
}

.discover-security-item-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  /*leading-trim: both;*/
  /*text-edge: cap;*/
  font-family: Montserrat-Bold;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all .5s linear;
  width: 40%;
}

.discover-security-item:hover {
  background-image: unset;
  background-color: #FFFFFF;

  .discover-security-item-title {
    transform: translateX(230px);
  }
}

.discover-security-item-title img {
  width: 56px;
  height: 64px;
}

.discover-security-item-subtitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.discover-security-item-content {
  width: 100%;
  max-width: 236px;
  color: #949494;
  font-family: Montserrat-Light;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.main-component-2__left-side-position {
  align-items: flex-end;
}
.main-component-2-content-position {
  text-align: end;
}
.main-component-2 {
  display: flex;
  width: 1240px;
  margin-top: 250px;
  align-items: center;
  justify-content: space-between;
  gap: 11px;
  transition: all 2s ease;

  &__left-side {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 2s;
    gap: 36px;
  }

  .line-width_1 {
    width: 309px;
    height: 1px;
    background: linear-gradient(90deg, rgba(195, 16, 29, 0.00) 0%, #75030B 100%);
  }

  .line-width_2 {
    width: 309px;
    height: 1px;
    background: linear-gradient(-90deg, rgba(195, 16, 29, 0.00) 0%, #75030B 100%);

  }

  .main-component-2-subtitle {
    font-family: Montserrat-Bold;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: linear-gradient(#DA291C, #B4001E, #930210, #75030B);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .main-component-2-content {
    width: 266px;
    color: #949494;
    font-family: Montserrat-Light;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.discover-last-item {
  display: flex;
  align-items: center;
  //justify-content: center;
  background: linear-gradient(112deg, #EDEDED 12.45%, #EDEDED 12.46%, #F0F0F0 155.93%);
  width: 1240px;
  //height: 580px;
  margin-top: 160px;
  border-radius: 26px;
  padding: 24px 24px 24px 56px;
  justify-content: space-between;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

}

@media screen and (max-width: 1477px) {
  .main-component-2 {
    flex-wrap: wrap;
    justify-content: center;

    &__left-side {
      align-items: center;
      width: 1024px;
    }
  }
}

//FOR INVESTORS

.investors {
  width: 100%;
  display: flex;
  /*justify-content: center;*/
  flex-direction: column;
  align-items: center;
  margin-top: 55px;
  //background:red;
}

.forinvestors-text-block {
  width: 100%;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, #F7F7F7 100%);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.forinvestors-text {
  width: 1240px;
  color: #D7D7D7;
  font-family: Montserrat-Light;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  height: 70px;
  display: flex;
  align-items: center;
}

.investors-first-item {
  display: flex;
  align-items: center;
  //justify-content: center;
  background-image: url("./assets/investors-bg.svg");
  width: 1240px;
  height: 660px;
  margin-top: -69px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  border-radius: 0 0 26px 26px;
}

.offer-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 160px;
  gap: 48px;
  width: 1240px;
  flex-wrap: wrap;

  //background:grey;
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 17px;
    width: 100%;
  }
}

.offer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 28px;
  padding-right: 24px;
  width: 396px;
  height: 265px;
  flex-shrink: 0;
  background-image: url("./assets/offer.svg");
  background-repeat: no-repeat;
  background-size: cover;
  filter: drop-shadow(0px 4px 13.4px rgba(0, 0, 0, 0.13));
  cursor: pointer;

  &__content {
    margin-top: 97px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    &__title {
      font-family: Montserrat-Light;

      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);

      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &__text {
      color: #949494;
      font-family: Montserrat-Light;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.offer-group {
  display: flex;
  width: 1240px;
  gap: 25px;
  flex-wrap: wrap;

}


.svg-container {
  position: relative;
  width: 29px;
  height: 29px;
}

.svg-container svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.4s ease-in-out;
  cursor: pointer;
}
.svg-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.4s ease-in-out;
  cursor: pointer;
}

.svg1 {
  opacity: 1;
}

.svg2 {
  opacity: 0;
}

.svg-container:hover .svg1 {
  opacity: 0;
}

.svg-container:hover .svg2 {
  opacity: 1;
}
.image1 {
  opacity: 1;
}

.image2 {
  opacity: 0;
}

.svg-container:hover .image1 {
  opacity: 0;
}

.svg-container:hover .image2 {
  opacity: 1;
}

//Contacts

.contacts {
  width: 100%;
  display: flex;
  /*justify-content: center;*/
  flex-direction: column;
  align-items: center;
  margin-top: 55px;
  //background:red;
}

.contacts-first-item {
  width: 1240px;
}

.contacts-text {
  justify-content: flex-start;
  max-width: 1240px;
  width: 100%;
  color: #D7D7D7;
  font-family: Montserrat-Light;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  height: 70px;
  display: flex;
  align-items: center;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, #F7F7F7 100%);
  backdrop-filter: blur(2px);
}

.actions-group {
  display: flex;
  width: 1240px;
  gap: 22px;
  margin-top: 160px;
}

.address {
  display: flex;
  width: 293px;
  height: 266px;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 25px;
  position: relative;
  filter: drop-shadow(0px 4px 2.4px rgba(0, 0, 0, 0.13));
  overflow: hidden;
}

.address-top {
  width: 350px;
  height: 294px;
  border-radius: 25px 25px 25px 25px;
  position: absolute;
  margin-top: -10px;
}

.address-title {
  font-family: Montserrat-Bold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
  bottom: 26px;
  z-index: 5;
}
.social-contact-group {
  position: absolute;
  bottom: 26px;
  z-index: 5;
}

.social-contact-group-logo {
  display: flex;
  align-items: center;
  gap: 12px;
  transition: width 0.4s ease;
  width: 160px;
  //overflow-y: scroll;
  overflow-x: auto !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  scroll-behavior: smooth !important;
}

.open-button {
  display: flex;
  width: 181px;
  height: 59px;
  padding: 24px 44px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 500px;
  border: 1px solid #75030B;
  background: #FFF;
  position: absolute;
  top: 115px;
  cursor: pointer;
  opacity: 0;
  transition: all 0.7s ease;
  white-space: nowrap;

  a {
    text-decoration: none;
    color: #B21815;
    font-family: Montserrat-Bold;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 114.5%;
    transition: all 0.5s ease;
  }
}

.open-button:hover {
  background: #B21815;
  opacity: 1;
  border: unset;

  a {
    color: #FFFFFF;
  }
}

.address:hover {
  .open-button {
    opacity: 1;
    transform: translate(0, -80%);
  }
}

.telephone {
  display: flex;
  width: 293px;
  height: 266px;
  justify-content: center;
  border-radius: 25px;
  position: relative;
  filter: drop-shadow(0px 4px 2.4px rgba(0, 0, 0, 0.13));
  overflow: hidden;
}

.tel-top {
  width: 293px;
  //height: 266px;
  border-radius: 25px 25px 0 0;
  position: absolute;
  top: 0
}

.tel-bottom {
  width: 293px;
  //height: 255px;
  border-radius: 0 0 25px 25px;
  position: absolute;
  bottom: 0px;
  z-index: 4;
}

.tel-inner {
  //width: 293px;
  //height: 250px;
  position: absolute;
  z-index: 4;
  top: -10px;
  transition: all 1s ease;
}

.telephone:hover {
  .tel-inner {
    top: 10px;
    //transform: translate(0, 8px);
  }
}

.email {
  display: flex;
  width: 293px;
  height: 266px;
  justify-content: center;
  border-radius: 25px;
  position: relative;
  filter: drop-shadow(0px 4px 2.4px rgba(0, 0, 0, 0.13));
  overflow: hidden;
}

.email-top {
  width: 325px;
  border-radius: 25px 25px 0 0;
  position: absolute;
  top: -35px;
  transition: all 1s ease;
}

.email-bottom {
  width: 293px;
  border-radius: 0 0 25px 25px;
  position: absolute;
  bottom: 0;
  z-index: 4;
  overflow: hidden;

}

.email:hover {
  .email-top {
    top: -10px;
  }
}

.social {
  display: flex;
  width: 293px;
  height: 266px;
  justify-content: center;
  border-radius: 25px;
  position: relative;
  cursor: pointer;
  filter: drop-shadow(0px 4px 2.4px rgba(0, 0, 0, 0.13));
  overflow: hidden;
}

.social-top {
  width: 293px;
  border-radius: 25px 25px 0 0;
  position: absolute;
  top: 0
}

.social-bottom {
  width: 293px;
  border-radius: 0 0 25px 25px;
  position: absolute;
  bottom: 0px;
  z-index: 4;
}

.social-inner {
  position: absolute;
  z-index: 4;
  top: -40px;
  transition: all 1s ease;
}

.social:hover {
  .social-inner {
    top: -20px;
  }
}

.svgbig-container {
  position: relative;
  width: 182px;
  height: 65px;
}

.svgbig-container svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.4s ease-in-out;
  cursor: pointer;
}

.svgbig-container1 {
  position: relative;
  width: 192px;
  height: 50px;
}

.svgbig-container1 svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 82px;
  //height: 100%;
  transition: opacity 0.4s ease-in-out;
  cursor: pointer;
}

.svg1 {
  opacity: 1;
}

.svg2 {
  opacity: 0;
}

.svgbig-container:hover .svg1 {
  opacity: 0;
}

.svgbig-container:hover .svg2 {
  opacity: 1;
}

.svgbig-container1:hover .svg1 {
  opacity: 0;
}

.svgbig-container1:hover .svg2 {
  opacity: 1;
}

//AIRDROP
.airdrop {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 55px;
}

.airdrop-first-item {
  background: url("./assets/airdrop-first-bg.svg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 708px;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.airdrop-text {
  justify-content: center;
  width: 100%;
  color: #D7D7D7;
  font-family: Montserrat-Light;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  height: 70px;
  display: flex;
  align-items: center;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, #F7F7F7 67%);
  backdrop-filter: blur(2px);

  span {
    width: 1240px;
  }
}

.airdrop-last-item {
  display: flex;
  align-items: center;
  //background: linear-gradient(112deg, #EDEDED 12.45%, #EDEDED 12.46%, #F0F0F0 155.93%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: #D9D9D9;
  width: 1240px;
  margin-top: 35px;
  border-radius: 27px;
  padding: 24px 24px 24px 56px;
  justify-content: space-between;
}

.airdrop-nft-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.nft-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  .name {
    font-family: Montserrat-Bold;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 114.5%;
    background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);

    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    .creator-name {
      color: #949494;
      font-family: Montserrat-Light;

      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .price {
      color: #949494;
      font-family: Montserrat-Light;

      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}

.airdrop-countdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.airdrop-countdown-small {
  width: 100%;
}

.nft-item {
  display: flex;
  flex-direction: column;
  width: 293px;
  height: 430px;
  border-radius: 25px;
  //background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.60) 57.4%, rgba(255, 255, 255, 0.60) 100%);
  background: url("./assets/nft-item-bg.svg");
  padding: 16px 16px 33px 16px;
  gap: 16px;
  filter: drop-shadow(0px 4px 4.4px rgba(0, 0, 0, 0.13));
  overflow: hidden;
  transition: all .8s ease;
  cursor: pointer;

  .nft-item-picture {
    img {
      border-radius: 12px;
      transition: all .8s ease;
      width: 100%;
      height: 283px;
      object-fit: cover;
    }
  }

  &_details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding-top: 8px;
    background: url("./assets/nft-item-bg.svg");
    transition: all .8s ease;

    &__title-group {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &__title {
        color: #949494;
        font-family: Montserrat-Light;

        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      &__owner-name {
        color: #949494;
        font-family: Montserrat-Light;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    &__content {
      display: flex;
      flex-direction: row;
      gap: 46px;

      &___item {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &____top {
          color: #949494;
          font-family: Montserrat-Light;

          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: uppercase;
        }

        &____bottom {
          font-family: Montserrat-Bold;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: uppercase;
          background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

  }
}

.nft-item:hover {
  transform: translateY(-14px);

  .nft-item_details {
    transform: translateY(-72px);
  }

  .make-offer-button {
    transform: translateY(-8px);
  }
}

.make-offer-button {
  display: flex;
  width: 100%;
  padding: 20px 44px;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  color: #FFF;
  font-family: Montserrat-Light;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  /* 17.175px */
  cursor: pointer;
  background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);

  //-webkit-text-fill-color: transparent;
  background-size: 400% 100%;
  background-position: 75% center;
  transition: all .8s linear;
  margin-top: 10px;
}

.make-offer-button:hover {
  background-position: 100% center;
}


.nft-airdrop-button {
  display: flex;
  /*min-width: 100px;*/
  padding: 24px 44px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 500px;
  color: #FFF;
  font-family: Montserrat-Light;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  /* 17.175px */
  cursor: pointer;
  background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);

  //-webkit-text-fill-color: transparent;
  background-size: 400% 100%;
  background-position: 75% center;
  transition: background 0.3s linear;
  //margin-top: 40px;
  width: 171px;
  text-transform: uppercase;
}

.nft-airdrop-button:hover {
  background-position: 100% center;
}

.nft-group-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mt-160 {
  margin-top: 160px !important;
}

//Market

.market {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 55px;
}

.market-buttons-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 1240px;
  margin-top: 30px;
  gap: 10px;
}

.market-button {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #D7D7D7;
  cursor: pointer;
  transition: all .3s ease;

  span {
    color: #D7D7D7;
    font-family: Montserrat-Light;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
}

.market-button:hover {
  border-radius: 4px;
  border: 1px solid #75030B;
  background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.button-is-active {
  border-radius: 4px;
  background: #F4F4F4;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  color: #353535;
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border: 1px solid #D7D7D7;
  cursor: pointer;
  transition: all .3s ease;

  span {
    color: #949494;
    font-family: Montserrat-Light;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
}

.market-block1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 52px;
  gap: 48px;
  width: 1240px;
  flex-wrap: wrap;
}

//NFTDETAILS

.nft-details-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  //margin-top: 96px;
  gap: 48px
}

.nft-details-first {
  display: flex;
  width: 1240px;
  //align-items: center;
  justify-content: space-between;
  margin-top: 102px;

  //background: greenyellow;
  &_left {
    display: flex;
    width: 511px;
    //height: 511px;
    align-items: center;
    justify-content: center;

    img {
      width: 511px;
      height: 511px;
      object-fit: cover;
      border-radius: 26px;
    }
  }

  &_right {
    //background: blue;
    max-width: 660px;
    width: 100%;

    &__name {
      margin-top: 30px;
      font-family: Montserrat-Bold;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 114.5%;
      background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);

      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &__collection-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 8px;

      &___owner {
        color: #949494;
        font-family: Montserrat-Light;

        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        span {
          color: #949494;
          font-family: Montserrat-Bold;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      &___collection-name {
        display: flex;
        gap: 6px;

        span {
          font-family: Montserrat-Light;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);

          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

    }
  }

}

.rating {
  display: flex;
  gap: 16px;

  &_items {
    display: flex;
    gap: 8px;

    span {
      color: #949494;
      font-family: Montserrat-Light;

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.mt-30 {
  margin-top: 30px;
}

.sale-end {
  display: flex;
  gap: 8px;

  span {
    color: #949494;
    font-family: Montserrat-Light;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  div {
    color: #949494;
    font-family: Montserrat-Bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.time-text {
  color: #949494;
  font-family: Montserrat-Bold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  min-width: 53px;
}

.collection-actions {
  padding: 28px 32px;
  background: #F3F3F3;
  margin-top: 30px;
  border-radius: 26px;

  &_title {
    color: #949494;
    font-family: Montserrat-Light;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 6px;
  }

  &_current-price {
    display: flex;
    gap: 12px;
    align-items: flex-end;

    &_bnb {
      color: #949494;
      font-family: Montserrat-Bold;
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    &_usd {
      color: #949494;
      font-family: Montserrat-Bold;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 2px;
    }

  }
}

.nft-buy-buttons-gropup {
  display: flex;
  gap: 16px;
  margin-top: 16px;
  height: 59px;
}

.nft-buy-button {
  display: flex;
  width: 293px;
  padding: 24px 44px;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);
  color: #FFF;
  font-family: Montserrat-Light;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  /* 17.175px */
  cursor: pointer;
  background-size: 400% 100%;
  background-position: 75% center;
  transition: background 0.3s linear;
  gap: 10px;
}

.nft-buy-button:hover {
  background-position: 100% center;
}

.nft-drop-button {
  display: flex;
  min-width: 293px;
  padding: 24px 44px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 500px;
  border: 1px solid #B21815;
  background: #FFF;
  color: #B21815;
  font-family: Montserrat-Light;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  /* 17.175px */
  cursor: pointer;
  transition: 0.4s ease;

}

.nft-drop-button:hover {
  background: #B21815;
  color: #FFF;
}

.main-countdown {
  height: 96px;
  width: 100%;
  display: flex;
  margin-top: 16px;
}

.content-slider {
  text-align: center;
  margin-bottom: 30px;
}

.container-slider {
  margin: 0 auto;
  width: 1240px;

  //margin-top: -10px
  .slick-track {
    display: flex !important;
    gap: 16px !important;
  }

  .slick-initialized .slick-slide {
    width: 300px !important;

  }

  .slick-slider {
    width: 1265px !important;
    height: 480px;
    display: flex !important;
    align-items: center !important;
  }

  .slick-prev {
    display: flex !important;
    width: 35px !important;
    height: 425px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
    flex-shrink: 0 !important;
    border-radius: 26px !important;
    background: #F4F4F4 !important;
    box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13) !important;
    left: -32px !important;
    opacity: 0;
    transition: 0.5s ease;
  }

  .slick-next {
    display: flex !important;
    width: 35px !important;
    height: 425px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
    flex-shrink: 0 !important;
    border-radius: 26px !important;
    background: #F4F4F4 !important;
    box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13) !important;
    right: -32px !important;
    opacity: 0;
    transition: 0.5s ease;
  }

  .slick-next:before {
    content: url("./assets/icons/slider-next.svg") !important;
    margin-right: -12px;
  }

  .slick-prev:before {
    content: url("./assets/icons/slider-prev.svg") !important;
    margin-right: -3px;
  }

  .slick-next:hover {
    opacity: 1;
  }

  .slick-prev:hover {
    opacity: 1;
  }
}



.slick-next:before {
  content: url("./assets/icons/slider-next.svg") !important;
  margin-right: -12px;
}

.slick-prev:before {
  content: url("./assets/icons/slider-prev.svg") !important;
  margin-right: -3px;
}

.slick-next:hover {
  opacity: 1;
}

.slick-prev:hover {
  opacity: 1;
}


//NFTITEM SMALL

.nft-item-small {
  display: flex;
  flex-direction: column;
  width: 234px;
  height: 337px;
  border-radius: 25px;
  //background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.60) 57.4%, rgba(255, 255, 255, 0.60) 100%);
  background: url("./assets/nft-item-bg.svg");
  padding: 12px 8px 24px 8px;
  gap: 6px;
  filter: drop-shadow(0px 4px 2.4px rgba(0, 0, 0, 0.13));
  overflow: hidden;
  transition: all .8s ease;
  cursor: pointer;

  .nft-item-small-picture {
    img {
      border-radius: 12px;
      transition: all .8s ease;
      width: 100%;
      height: 205px;
    }
  }

  &_details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    background: url("./assets/nft-item-bg.svg");
    padding-top: 6px;
    transition: all .8s ease;

    &__title-group {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &__title {
        color: #949494;
        font-family: Montserrat-Light;

        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      &__authorname {
        color: #949494;
        font-family: Montserrat-Light;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &__owner-name {
        color: #949494;
        font-family: Montserrat-Light;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    &__content {
      display: flex;
      flex-direction: row;
      gap: 16px;

      &___item {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &____top {
          color: #949494;
          font-family: Montserrat-Light;

          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: uppercase;
        }

        &____bottom {
          font-family: Montserrat-Bold;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: uppercase;
          background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);

          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

  }
}

.nft-item-small:hover {
  transform: translateY(-8px);

  .nft-item-small_details {
    transform: translateY(-62px);
  }

  .make-offer-button {
    transform: translateY(-8px);
  }
}

.make-offer-small-button {
  display: flex;
  width: 100%;
  padding: 14px 44px;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  color: #FFF;
  font-family: Montserrat-Light;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  cursor: pointer;
  background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);

  //-webkit-text-fill-color: transparent;
  background-size: 400% 100%;
  background-position: 75% center;
  transition: all .8s linear;
  margin-top: 10px;
}

.make-offer-button:hover {
  background-position: 100% center;
}

.container-slider1 {
  margin: 0 auto;
  width: 1240px;

  .slick-track {
    display: flex !important;
    gap: 4px !important;
  }

  .slick-initialized .slick-slide {
    width: 250px !important;

  }

  .slick-slider {
    width: 1240px !important;
    height: 375px !important;
    display: flex !important;
    align-items: center !important;
  }

  .slick-prev {
    display: flex !important;
    width: 35px !important;
    height: 335px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
    flex-shrink: 0 !important;
    border-radius: 26px !important;
    background: #F4F4F4 !important;
    box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13) !important;
    left: -42px !important;
    opacity: 0;
    transition: 0.5s ease;
  }

  .slick-next {
    display: flex !important;
    width: 35px !important;
    height: 335px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
    flex-shrink: 0 !important;
    border-radius: 26px !important;
    background: #F4F4F4 !important;
    box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13) !important;
    right: -32px !important;
    opacity: 0;
    transition: 0.5s ease;
  }

  .slick-next:before {
    content: url("./assets/icons/slider-next.svg") !important;
    margin-right: -12px;
  }

  .slick-prev:before {
    content: url("./assets/icons/slider-prev.svg") !important;
    margin-right: -3px;
  }

  .slick-next:hover {
    opacity: 1;
  }

  .slick-prev:hover {
    opacity: 1;
  }
}


.nft-details-second {
  display: flex;
  width: 1240px;
  justify-content: space-between;

  //background: greenyellow;
  &_left {
    display: flex;
    flex-direction: column;

    &_header {
      width: 511px;
      height: 48px;
      display: flex;
      align-items: center;
      padding-left: 24px;
      background: #F4F4F4;
      box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
      border-radius: 9px;
      margin-bottom: -8px;
      z-index: 1;
      gap: 8px;

      span {
        background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);

        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: Montserrat-Regular;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    &_description {
      width: 511px;
      height: 155px;
      background: #F4F4F4;
      border-radius: 0 0 9px 9px;
      padding: 24px 8px 24px 24px;
      box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
      text-align: left;

      .scroll-div {
        overflow: auto;
        height: 112px;
      }

      span {
        color: #949494;
        font-family: Montserrat-Light;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }


    }
  }

  &_right {
    display: flex;
    flex-direction: column;
    margin-top: -8px;

    &_header {
      width: 655px;
      height: 48px;
      display: flex;
      align-items: center;
      padding-left: 24px;
      background: #F4F4F4;
      box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
      border-radius: 9px;
      margin-bottom: -8px;
      z-index: 1;
      gap: 8px;

      span {
        background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: Montserrat-Regular;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    &_description {
      position: relative;
      width: 655px;
      height: 220px;
      background: #F4F4F4;
      border-radius: 0 0 9px 9px;
      padding: 24px;
      box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
      display: flex;
      align-items: center;
      justify-content: center;

      .left-vertical {
        position: absolute;
        //background: red;
        rotate: -90deg;
        color: #949494;
        font-family: Montserrat-Bold;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        //width: 20px;
        text-align: center;
        top: 100px;
        left: -20px
      }

      .right-vertical {
        position: absolute;
        //background: red;
        rotate: 90deg;
        color: #949494;
        font-family: Montserrat-Bold;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        //width: 100%;
        top: 90px;
        right: -14px
      }

      .chart-content {
        display: flex;
        flex-direction: column;
        width: 510px;
        height: 150px;
        gap: 8px;
        //align-items: flex-start;
        justify-content: space-between;

        //background: greenyellow;
        &_item {
          //background: red;
          display: flex;
          width: 100%;
          align-items: flex-end;
          justify-content: space-between;
          height: 20px;
        }

        &_price {
          color: #949494;
          font-family: Montserrat-Light;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: -4px
        }

        &_line {
          width: 450px;
          height: 1px;
          background: #949494;
        }
      }
    }
  }
}

.chart {
  position: absolute;
  width: 43px;
  height: 125px;
  background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);

  top: 59px;
  left: 47%;
  border-radius: 9px 9px 0 0;
  text-align: center;
  cursor: pointer;

  .line-image {
    opacity: 0;
    margin-top: 16px;
    transition: opacity 0.5s ease;
  }

  .chart-info {
    position: absolute;
    width: 196px;
    height: 98px;
    background: #ffffff;
    padding: 12px;
    top: -82px;
    left: -76px;
    z-index: 55555;
    border-radius: 10px;
    transition: opacity 0.5s ease;
    opacity: 0;

    &_header {
      color: #949494;
      font-family: Montserrat-Bold;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &_content {
      color: #949494;
      font-family: Montserrat-Light;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.chart:hover {
  .line-image {
    opacity: 1;
  }

  .chart-info {
    opacity: 1;
  }

}

.dropdown-left {
  display: flex;
  gap: 8px;

  span {
    background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Montserrat-Regular;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.details-dropdown {
  &.ant-dropdown-trigger {
    width: 511px;
    height: 48px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    background: #F4F4F4;
    box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
    border-radius: 9px;
    margin-bottom: -8px;
    z-index: 1051;
    gap: 8px;
    margin-top: 16px;
  }
}


:where(.css-dev-only-do-not-override-1okl62o).ant-space-gap-col-small {
  display: flex;
  justify-content: space-between;
  width: 95%;
}

:where(.css-dev-only-do-not-override-1okl62o).ant-dropdown .ant-dropdown-menu {
  background: #F4F4F4 !important;
  //margin-top: -8px !important;
  padding: 12px;
}

.dropdown-items {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_left {
    color: #949494;
    font-family: Montserrat-Light;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &_right {
    color: #949494;
    font-family: Montserrat-Light;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.ant-dropdown-trigger {
  cursor: pointer;
}

//Loading

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust based on where you want to display the loader */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #B4001E;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
:where(.css-dev-only-do-not-override-1okl62o).ant-drawer {
  z-index: 1150;
}

w3m-modal {
  z-index: 999999999999!important;

}
.ant-popover-content {
  margin-top: 23px;
  width: 224px;
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
}


.connect-icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  height: 44px;
  cursor: pointer;
  padding-left: 8px;
  span {
    color: #949494;
    font-family: Montserrat-Light;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  img {
    width: 22px;
    height: 22px;
  }
}

.connect-icon:hover {
  background: #EDEDED;
  border-radius: 8px;
}

//ASSETS

.assets {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 53px;
}
.assets-text-block {
  width: 100%;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, #F7F7F7 100%);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.assets-text {
  justify-content: flex-start;
  width: 1240px;
  color: #D7D7D7;
  font-family: Montserrat-Light;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  height: 70px;
  display: flex;
  align-items: center;
}
.assets-group {
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
  width: 1240px;
  margin-top: 32px;
}
.assets-group-web {
  display: flex;
  gap: 22px;
  width: 1240px;
  margin-top: 32px;
}
.assets-group-mob {
  display: flex;
  gap: 22px;
  width: 1240px;
  margin-top: 32px;
}
@media screen and (max-width: 1023px) {
  .assets-group-web {
    display: none;
  }
  .discover-security-item-title {
    img {
      width: 42px;
      height: 100%
    }
  }
  .discover-security-item:hover {
    background-image: unset;
    background-color: #FFFFFF;

    .discover-security-item-title {
      transform: translateX(120px);
      img {
        width: 42px;
        height: 100%
      }
    }
  }
}
@media screen and (min-width: 1023px) {
  .assets-group-mob {
    display: none;
  }
}
.add-nft-card {
  width: 293px;
  padding: 36px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.60);
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  &_top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    img {
      width: 58px;
      height: 58px;
    }
  }
  &_text {
    leading-trim: both;
    text-edge: cap;
    font-family: Montserrat-Regular;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &_center {
    width: 191px;
    color:#949494;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Montserrat-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &_button {
    display: flex;
    width: 181px;
    padding: 24px 44px;
    justify-content: center;
    align-items: center;
    border-radius: 500px;
    background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);
    color: #FFF;
    font-family: Montserrat-Light;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 114.5%;
    cursor: pointer;
    background-size: 400% 100%;
    background-position: 75% center;
    transition: background 0.3s linear;
  }

  &_button:hover {
    background-position: 100% center;
  }
}

.assets-nft-card {
  width: 293px;
  display: flex;
  align-items: center;
  //justify-content: center;
  flex-direction: column;
  border-radius: 25px;
  box-shadow: 0px 4px 4px 0px rgba(212, 212, 212, 0.25) inset;
  filter: drop-shadow(0px 4px 13.4px rgba(0, 0, 0, 0.13));
  background: url("./assets/bg-mask-group.png");
  //background: url("./assets/bg-mask-group.png") lightgray 50% / cover no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 16px 16px 33px 16px;
  gap: 16px;
  //position: relative;
  &_nftimage {
    width: 261px;
    height: 294px;
    flex-shrink: 0;
    filter: drop-shadow(0px 4px 13.4px rgba(0, 0, 0, 0.13));
    border-radius: 9px;
    background: #D9D9D9;
    img {
      border-radius: 9px;
      width: 261px;
      height: 294px;
      filter: drop-shadow(0px 4px 13.4px rgba(0, 0, 0, 0.13));
    }
    &_status {
      position: absolute;
      right: 16px;
      bottom: 24px;
      display: inline-flex;
      height: 26px;
      padding: 8px 10px;
      align-items: center;
      gap: 4px;
      flex-shrink: 0;
      border-radius: 500px;
      background: #FFF;
      box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.20);
      img {
        width: 12px;
        height: 12px;
      }
    }
  }
  &_footer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
    &_title {
      color: #949494;
      font-family: Montserrat-Regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    &_details-group {
      display: flex;
      gap: 46px;
    }
    &_details {
      display: flex;
      flex-direction: column;
      gap: 8px;
      &_top {
        color: #949494;
        font-family: Montserrat-Light;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
      }
      &_bottom {
        font-family: Montserrat-Bold;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

}
.moderation {
  color: #949494;
  font-family: Montserrat-Bold;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.active {
  color: #00B73E;
  font-family: Montserrat-Bold;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.not-active {
  color: #B21815;
  font-family: Montserrat-Bold;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

//SALE
.sale {
  margin-top: 150px;
  width: 975px;
  //background: #ece6e6;
  display: flex;
  justify-content: space-between;
  gap: 41px;
  .sale-left-side {
    width: 100%;
    display: flex;
    flex-direction: column;
    &_title {
      font-family: Montserrat-Bold;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .sale-right-side {
    width: 305px;
  }
}

.add-nft-form {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  //background: greenyellow;
  &_item {
    display: flex;
    flex-direction: column;
    gap: 16px;
    &__title {
      font-family: Montserrat-Bold;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
.set-price-group {
  display: flex;
  justify-content: space-between;
}
.set-price-input {
  width: 100%;
  height: 48px;
  border-radius: 100px 0 0 100px;
  background: #FFF;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  border: unset;
  padding-left: 24px;
  color: #949494;
  font-family: Montserrat-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
}
.set-price-input::-webkit-input-placeholder {
  color: #D7D7D7;
  font-family: Montserrat-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
}

.set-price-input-select {
  width: 135px;
  height: 48px;
  .ant-select-selector::before {
    content:" ";
    display: block;
    width: 1px;
    height: 32px;
    position: absolute;
    top: 20%;
    left: 0;
    background: #D7D7D7;
  }
}

.ant-select-selector {
  border-radius: 0 100px 100px 0!important;
  border: unset!important;
  background: #FFF;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
}

:where(.css-dev-only-do-not-override-1okl62o).ant-select .ant-select-selection-item {
  color: #949494;
  font-family: Montserrat-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  margin-left: 8px;
}
:where(.css-dev-only-do-not-override-1okl62o).ant-select .ant-select-arrow .anticon {
  width: 15px;
  height: 13px;
  margin-right: 20px;
}
input:focus-visible {
  outline: unset;
}
.set-price-group:focus-visible {
  outline: unset;
}
.period-selector {
  width: 100%;
  height: 48px;
  .ant-select-selector {
    border-radius: 100px!important;
    border: unset!important;
    background: #FFF;
    box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  }
}

.token-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 12px;
}
.token-info-input {
  width: 100%;
  height: 48px;
  border-radius: 100px;
  background: #FFF;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  border: unset;
  padding-left: 24px;
  color: #949494;
  font-family: Montserrat-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
}
.token-info-input::-webkit-input-placeholder {
  color: #D7D7D7;
  font-family: Montserrat-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
}
.w-100 {
  width: 100%;
}
.nft-description {
  width: 100%;
  height: 88px;
  border-radius: 9px;
  background: #FFF;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  border: unset;
  padding: 24px;
  color: #949494;
  font-family: Montserrat-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  resize: none;
  display:block;

}
.nft-description::-webkit-input-placeholder {
  color: #D7D7D7;
  font-family: Montserrat-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
}
.nft-description:focus-visible {
  outline: unset!important;
}

.summary {
  width: 100%;
  border-radius: 9px;
  background: #FFF;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  &_top {
    display: flex;
    gap: 16px;
    flex-direction: column;
    &__item {
      display: flex;
      justify-content: space-between;
      span {
        color: #949494;
        font-family: Montserrat-Light;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  &_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_left {
      color: #949494;
      font-family: Montserrat-Regular;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    &_right {
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: flex-end;
      &_crypto {
        color: #949494;
        font-family: Montserrat-Bold;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      &_usd {
        color: #949494;
        font-family: Montserrat-Light;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

    }
  }
}

.listing-button {
  width: 100%;
  display: flex;
  padding: 24px 44px;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  color: #FFF;
  font-family: Montserrat-ExtraLight;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  cursor: pointer;
  background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);
  /*-webkit-text-fill-color: transparent;*/
  background-size: 400% 100%;
  background-position: 75% center;
  transition: background 0.3s linear;
  border: unset;
  margin-top: 8px;
}
.listing-button:hover {
  background-position: 100% center;
}
.error-border {
  border: 1px solid red
}

.sales-nft-card {
  width: 293px;
  display: flex;
  align-items: center;
  //justify-content: center;
  flex-direction: column;
  border-radius: 25px;
  box-shadow: 0px 4px 4px 0px rgba(212, 212, 212, 0.25) inset;
  filter: drop-shadow(0px 4px 13.4px rgba(0, 0, 0, 0.13));
  background: url("./assets/bg-mask-group.png");
  //background: url("./assets/bg-mask-group.png") lightgray 50% / cover no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 16px;
  gap: 16px;
  //position: relative;
  &_nftimage {
    width: 261px;
    height: 294px;
    flex-shrink: 0;
    filter: drop-shadow(0px 4px 13.4px rgba(0, 0, 0, 0.13));
    border-radius: 9px;
    background: #D9D9D9;
    img {
      border-radius: 9px;
      width: 261px;
      height: 294px;
      filter: drop-shadow(0px 4px 13.4px rgba(0, 0, 0, 0.13));
    }
  }
  &_footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    &_top {
      display: flex;
      flex-direction: column;
      gap: 8px;
      &_price1 {
        color: #949494;
        font-family: Montserrat-Lightt;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      &_price2 {
        color: #949494;
        font-family: Montserrat-Lightt;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    &_bottom {
      font-family: Montserrat-Regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
.accept-offer {
  display: flex;
  width: 305px;
  height: 59px;
  padding: 24px 44px;
  min-width: 181px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 500px;
  border: 1px solid #B21815;
  background: #FFF;
  color: #B21815;
  font-family: Montserrat-Light;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  cursor: pointer;
  transition: 0.4s ease;
  margin-top: 16px;
}
.accept-offer:hover {
  background: #B21815;
  color: #FFF;
}
//Approve Popup

.approve-popup:where(.css-dev-only-do-not-override-1okl62o).ant-modal .ant-modal-content {
  border-radius: 26px;
  background: linear-gradient(341deg, #EDEDED 12.22%, #FFF 86.92%);
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  padding: 64px 40px;
}
.approve-popup:where(.css-dev-only-do-not-override-1okl62o).ant-modal .ant-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.approve-listing-modal-title {
  font-family: Montserrat-Bold;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
.approve-listing-modal-content {
  width: 100%;
  border-radius: 9px;
  background: #FFF;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  padding: 21px 24px;
  display: flex;
  justify-content: space-between;
  &_left {
    display: flex;
    gap: 12px;
    &_nft {
      width: 102px;
      height: 110px;
    }
    &_nft-info {
      display: flex;
      flex-direction: column;
      gap: 24px;
      justify-content: center;
      &_top {
        display: flex;
        flex-direction: column;
        gap: 12px;
        &_price1 {
          color: #949494;
          font-family: Montserrat-Light;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        &_price2 {
          color: #949494;
          font-family: Montserrat-Light;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      &_chain {
        color: #949494;
        font-family: Montserrat-Light;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  &_right {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    align-items: flex-end;
    &_price-bnb {
      color: #949494;
      font-family: Montserrat-Light;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    &_price-usd {
      color: #949494;
      font-family: Montserrat-Light;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
.approve-listing-modal-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 13px;
  &_cancel {
    display: flex;
    width: 258px;
    height: 59px;
    padding: 24px 44px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 500px;
    border: 1px solid #B21815;
    background: #FFF;
    color: #B21815;
    font-family: Montserrat-Light;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 114.5%;
    cursor: pointer;
    transition: 0.4s ease;
  }
  &_cancel:hover {
    background: #B21815;
    color: #FFF;
  }
  &_approve {
    width: 258px;
    height: 59px;
    display: flex;
    padding: 24px 44px;
    justify-content: center;
    align-items: center;
    border-radius: 500px;
    color: #FFF;
    font-family: Montserrat-Light;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 114.5%;
    cursor: pointer;
    background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);
    background-size: 400% 100%;
    background-position: 75% center;
    transition: background 0.3s linear;
    border: unset;
  }
  &_approve:hover {
    background-position: 100% center;
  }
}

.listing-button {
  width: 100%;
  display: flex;
  padding: 24px 44px;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  color: #FFF;
  font-family: Montserrat-ExtraLight;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  cursor: pointer;
  background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);
  /*-webkit-text-fill-color: transparent;*/
  background-size: 400% 100%;
  background-position: 75% center;
  transition: background 0.3s linear;
  border: unset;
  margin-top: 8px;
}
.listing-button:hover {
  background-position: 100% center;
}

//NFT INFO

.info {
  width: 975px;
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  margin-bottom: 8px;
  &_title {
    font-family: Montserrat-Bold;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &_item {
    display: flex;
    flex-direction: column;
    gap: 16px;
    &_title {
      font-family: Montserrat-Light;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &_content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      &_first-block{
        border-radius: 9px;
        background: #FFF;
        box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
        padding: 32px 108px 32px 32px;
        span {
          color: #949494;
          font-family: Montserrat-Light;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      &_second-block{
        border-radius: 9px;
        width: 475px;
        box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
        padding: 16px;
      }
    }
  }
}
:where(.css-dev-only-do-not-override-1okl62o).ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector {
  background: #FFF;
}
.list-for-sale-info {
  border-radius: 9px;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  padding: 24px 12px 12px 24px;
}
.margin-top-0 {
  margin-top: 0;
}
.not-hover:hover {
  background: unset;
  color: #B21815;
}
:where(.css-dev-only-do-not-override-1okl62o).ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector {
  cursor: auto;
}

.cursor-pointer {
  cursor: pointer;
}
.border-red {
  border: 1px solid #75030B;
}
.staking-drawer {
  //margin-top: 30px;
}

.staking-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 26px;
  &_title {
    font-family: Montserrat-Bold;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    background: var(--1, linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    &_item {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      &_left {
        color: #949494;
        font-family: Montserrat-Light;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      &_right {
        color: #949494;
        font-family: Montserrat-Light;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}
.for-claim-title {
  font-family: Montserrat-Bold;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 50px;
}
.input-claim-count {
  display: flex;
  width: 222px;
  height: 42px;
  padding: 14px 17px 14px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 9px;
  background: #FFF;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  color: #949494;
  font-family: Montserrat-Light ;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: unset;
  margin-top: 24px;
}
.staking-buttons-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
  &_claim-button {
    width: 222px;
    height: 59px;
    display: flex;
    padding: 24px 44px;
    justify-content: center;
    align-items: center;
    border-radius: 500px;
    color: #FFF;
    font-family: Montserrat-Light;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 114.5%;
    cursor: pointer;
    background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);
    background-size: 400% 100%;
    background-position: 75% center;
    transition: background 0.3s linear;
    border: unset;
  }
  &_claim-button:hover {
    background-position: 100% center;
  }
  &_burn-button {
    display: flex;
    width: 222px;
    height: 59px;
    padding: 24px 44px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 500px;
    border: 1px solid #B21815;
    background: #FFF;
    color: #B21815;
    font-family: Montserrat-Light;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 114.5%;
    cursor: pointer;
    transition: 0.4s ease;
  }
  &_burn-button:hover {
    background: #B21815;
    color: #FFF;
  }
}

//MODERATION

.moderation-main {
  width: 1240px;
  //height: 547px;
  border-radius: 9px;
  background: #F4F4F4;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  &_title {
    display: flex;
    width: 100%;
    padding: 16px 24px;
    align-items: center;
    gap: 10px;
    border-radius: 9px;
    background: #F4F4F4;
    box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
    span {
      font-family: Montserrat-Bold;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &_buttons-group {
    display: flex;
    gap: 10px;
    padding: 0 24px;
  }
}
.table-button {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #D7D7D7;
  cursor: pointer;
  color: #D7D7D7;
  font-family: Montserrat-Light;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  transition: all .3s ease;

}

.table-button-active {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #F4F4F4;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  border: unset;
  color: #949494;
  border: 1px solid #D7D7D7;
  cursor: pointer;
  transition: all .3s ease;
}

.moderation-table-input {
  width: 120px;
  height: 35px;
  background: #F0F0F0;
  border-radius: 100px;
  border: unset;
  padding: 0 20px;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  color: #949494;
  font-family: Montserrat-Bold;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
}
.table-container {
  width: 100%;
  height: 422px;
  padding: 0 7px 48px 0;
  &_header {
    display: flex;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #D7D7D7;
    //background: blue;
    justify-content: space-between;
    align-items: center;
    padding: 0 78px 0 24px;
    span {
      color: #949494;
      font-family: Montserrat-Light;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      justify-content: flex-start;
      width: 100%;
    }
  }
  &_item {
    display: flex;
    width: 100%;
    height: 120px;
    border-bottom: 1px solid #D7D7D7;
    //background: blue;
    justify-content: space-between;
    align-items: center;
    padding: 0 78px 0 24px;
    span {
      color: #949494;
      font-family: Montserrat-Light;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      justify-content: flex-start;
      width: 100%;
    }
  }
}
.table-icon {
  width: 80px;
  height: 100px;
  border-radius: 12px;
}
.custom-table {
  overflow: auto;
  height: 100%;
  //background: red;
}

.empty-table {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat-Light;
  font-size: 78px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  color: #949494;
  text-shadow: rgba(0, 0, 0, 0.13) 4px 4px;
}
.status-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 100%;
  border: 1px solid #949494;
  transition: .5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  svg path {
    transition: .5s ease;
  }
}
.status-icon:hover {
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: 1px solid #B4001E;
  svg path {
    fill: #B4001E;
  }
}
.status-icon-active {
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 100%;
  transition: .5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #B4001E;
  svg path {
    fill: #B4001E;
  }
}
.sale-mob-title {
  display: none;
  font-family: Montserrat-Bold;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 24px;
}
@media screen and (max-width: 1024px) {
  .sale {
    margin-top: 71px;
    width: 89%;
    flex-direction: column-reverse;
    align-items: center;
    gap: 0px;
    .sale-left-side_title {
      display: none;
    }
    .sale-mob-title {
      display: flex;
    }
  }
  .summary_bottom {
    display: block;
  }
  .summary_bottom_right {
    display: block;
    margin-top: 8px;
  }
  .info {
    width: 90%;
  }
  .info_item_content_first-block {
    padding: 24px;
  }
  .info_item_content_second-block {
    width: 100%;
  }
  .moderation-main {
    margin-top: 82px;
    width: 1000px !important;
  }
  .table-button {
    padding: 12px 16px;
  }
  .actions-position {
    margin-top: 26px!important;
    height: 177px!important;
    padding: 32px 24px!important;

  }
  .button-position {
    height: 50px!important;
    margin-top: 0px!important;
  }
  .discover-security-group {
    height: 245px;
  }

}

@media screen and (max-width: 1260px) {
  .moderation-scroll {
    width: 100%;
    overflow-x: scroll;
    padding: 0 24px;
    scrollbar-width: none !important;
  }
  .moderation-main {
    width: 100%;
  }
}
@media screen and (max-width: 1260px) {
  .assets-text-block {
    margin-top: -16px;
  }
}
@media screen and (max-width: 1024px) {
  .assets-text-block {
    width: 350px;
    margin-top: 0;
  }
}
@media screen and (max-width: 1300px) {
  .assets-group {
    width: 100%;
    overflow-x: scroll;
    scrollbar-width: none !important;
    padding: 24px;
  }
  .assets-group-web {
    width: 100%;
    overflow-x: scroll;
    scrollbar-width: none !important;
    padding: 12px 24px;
  }
  .assets-group-mob {
    width: 100%;
    overflow-x: scroll;
    scrollbar-width: none !important;
    padding: 12px 24px;
  }
  .add-nft-card {
    min-width: 293px;
  }
  .container-slider {
    display: none;
  }
  .container-slider1 {
    display: none;
  }
  .nft-details-first {
    width: 100%;
    flex-direction: column;
    align-items: center;
    &_left {
      flex-direction: column;
    }
    &_left img {
      width: 770px;
    }
    &_right {
      &__name {
        display: none;
      }
      &__collection-details {
        display: none;
      }
    }
  }
  .nft-details-second {
    width: auto;
    flex-direction: column-reverse;
    align-items: center;
    gap: 24px;
  }
  .mobile-roadmap-title {
    font-size: 26px!important;
    margin-top: -4px!important;
    span {
      font-size: 16px!important;
    }
  }

  .security-item-title {
    gap: 12px
  }

  .security-item-title img {
    width: 54px;
    height: 64px;
  }

  .security-item-subtitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .security-item-content {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 291px;
  }

  .security-item {
    gap: 20px;
    width: 291px;
    height: 237px;
    //padding: 0px 21px 0 18px
  }

  .security-group {
    gap: 16px;
    /*background: red;*/
    width: 100%;
    margin-left: 22px;
    /*margin-right: 52px;*/
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    //scroll-behavior: smooth;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.mobile-staking-content-main {
  width: 100%;
  padding: 0 24px;
  display: none;
}
@media screen and (max-width: 1024px) {
  .mobile-staking-content-main {
    display: block;
  }
  .staking-drawer {
    display: none;
  }
  .nft-details-first {
    margin-top: 52px;
    &_left {
      width: 350px;
      padding: 0 12px;
      img {
        width: 100%;
        height: 336px;
      }
    }
    &_right {
      width: 350px;
      padding: 0 12px;
    }
  }
  .rating_items {
    img {
      width: 14px;
      height: 14px;
    }
  }
  .nft-details-mobile-header {
    width: 100%!important;
  }
  .rating_items span {
    font-size: 14px;
  }
  .collection-actions {
    padding: 28px 24px;
    margin-top: 0px;
  }
  .nft-buy-buttons-gropup {
    flex-direction: column;
    height: 100%;
    gap: 10px;
  }
  .nft-buy-button {
    width: 100%;
    height: 50px;
  }
  .nft-drop-button {
    width: 100%;
    min-width: 100%;
    height: 50px;
  }
  .collection-actions_current-price_bnb {
    font-size: 24px;
  }
  .collection-actions_title {
    font-size: 16px;
  }
  .collection-actions_current-price_usd {
    font-size: 14px;
  }
  .nft-details-second {
    width: 350px;
    padding: 0 12px;
    &_left {
      width: 100%;
      &_header {
        width: 100%;
        height: 42px;
        img {
          width: 16px;
        }
        span {
          font-size: 16px;
        }
      }
      &_description {
        width: 100%;
        span {
          font-size: 12px;
        }
      }
    }
    &_right {
      width: 100%;
      &_header {
        width: 100%;
        height: 42px;
        img {
          width: 16px;
        }
        span {
          font-size: 16px;
        }
      }
      &_description {
        width: 100%;
      }
    }
  }
  .chart-content {
    width: 100%;
    &_line {
      width: 80%!important;
    }
  }
  .left-vertical {
    display: none!important;
  }
  .right-vertical {
    display: none!important;
  }
  .chart {
    width: 30px;
  }
  .details-dropdown.ant-dropdown-trigger {
    width: 100%;
    height: 42px;
  }
  .dropdown-left {
    img {
      width: 16px;
    }
    span {
      font-size: 16px;
    }
  }
  .dropdown-items_left{
    font-size: 12px;
  }
  .dropdown-items_right{
    font-size: 12px;
  }

}



.mobile-staking-content {
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  border-radius: 9px;
  align-items: flex-start;
  background: #FFF;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  margin-top: 24px;
  &_header {
    font-family: Montserrat-Bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 20px;
  }
  &_row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    &_left {
      color: #949494;
      font-family: Montserrat-Light;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 16px;
    }
    &_right {
      color: #949494;
      font-family: Montserrat-Light;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  &_bottom {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 14px;
    &_left {
      font-family: Montserrat-Bold;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &_right {
      color: #949494;
      font-family: Montserrat-Light;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

}

.mobile-staking-buttons-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
  margin-top: 24px;
  &_burn {
    display: flex;
    width: 100%;
    height: 50px;
    padding: 24px 44px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 500px;
    border: 1px solid #B21815;
    background: #FFF;
    color: #B21815;
    font-family: Montserrat-Light;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 114.5%;
    cursor: pointer;
    transition: 0.4s ease;
  }
  &_burn:hover {
    background: #B21815;
    color: #FFF;
  }
  &_claim {
    width: 100%;
    height: 50px;
    display: flex;
    padding: 24px 44px;
    justify-content: center;
    align-items: center;
    border-radius: 500px;
    color: #FFF;
    font-family: Montserrat-Light;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 114.5%;
    cursor: pointer;
    background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);
    background-size: 400% 100%;
    background-position: 75% center;
    transition: background 0.3s linear;
    border: unset;
  }
  &_claim:hover {
    background-position: 100% center;
  }
}


@media screen and (max-width: 1024px) {
  .approve-listing-modal-content {
    flex-direction: column;
    gap: 12px;
  }
  .approve-listing-modal-content_right {
    align-items: flex-start;

  }

}

.new-content-header {
  display: flex;
  //width: 100%;
  height: 44px;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  justify-content: space-between;
  border-bottom: .5px solid #D7D7D7;
  margin-top: 8px;
  &_left {
    display: flex;
    gap: 8px;
    align-items: center;
    img {
      width: 22px;
      height: 20px;
    }
    span {
      color: #949494;
      font-family: Montserrat-Light;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &_right {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}
.new-content-body {
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-top: 16px;
  padding-left: 10px;
  padding-right: 10px;
  &_item {
    display: flex;
    justify-content: space-between;
    span {
      color: #949494;
      font-family: Montserrat-Light;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
.new-content-button {
  display: flex;
  height: 43px;
  padding: 24px 44px;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  border: 1px solid #949494;
  background: #FFF;
  margin-top: 34px;
  margin-bottom: 16px;
  color: #949494;
  font-family: Montserrat-Light;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  cursor: pointer;
}

.convert-listing-modal-buttons {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  &_convert {
    width: 163px;
    height: 59px;
    display: flex;
    padding: 24px 44px;
    justify-content: center;
    align-items: center;
    border-radius: 500px;
    color: #FFF;
    font-family: Montserrat-Light;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 114.5%;
    cursor: pointer;
    background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);
    background-size: 400% 100%;
    background-position: 75% center;
    transition: background 0.3s linear;
    border: unset;
  }
  &_convert:hover {
    background-position: 100% center;
  }
}

.convert-listing-modal-body {
  display: flex;
  gap: 12px;
  align-items: center;
}

.set-price-input-convert {
  width: 115px;
  height: 48px;
  border-radius: 100px 0 0 100px;
  background: #FFF;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  border: unset;
  padding-left: 24px;
  color: #949494;
  font-family: Montserrat-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
}
.set-price-input-convert::-webkit-input-placeholder {
  color: #D7D7D7;
  font-family: Montserrat-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
}

.set-price-input-select-convert {
  width: 115px;
  height: 48px;
  .ant-select-selector::before {
    content:" ";
    display: block;
    width: 1px;
    height: 32px;
    position: absolute;
    top: 20%;
    left: 0;
    background: #D7D7D7;
  }
}

.convert-input-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px
}
.convert-balance-info {
  color: #949494;
  font-family: Montserrat-Light;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.margin-top-icon {
  margin-top: -24px;
}

@media screen and (max-width: 1024px) {
  .convert-listing-modal-body {
    flex-direction: column;
    gap: 24px;
  }
  .margin-top-icon {
    margin-top: 0;
  }
}

:where(.css-dev-only-do-not-override-1okl62o).ant-modal-root .ant-modal-wrap {
  z-index: 5555555555;
}


//Roadmap GSAP

.roadmap-gsap {
  width: 1240px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 40px;
}

.grid {
  display: flex;
  padding: 69px;
  height: 500px;
}

.rg_item1 {
  width: 587px;
  height: 274px;
  border-radius: 25px;
  background: #FFF;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  display: flex;
  flex-direction: column;
  color: black;
  //align-items: center;
  //justify-content: center;
  font-size: 40px;
  /*overflow: hidden;*/
}

.rg_item2 {
  width: 271px;
  height: 99px;
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  display: flex;
  color: black;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  /*overflow: hidden;*/


}
.rg_item3 {
  width: 271px;
  height: 99px;
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  display: flex;
  color: black;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  /*overflow: hidden;*/


}
.rg_item4 {
  width: 291px;
  height: 99px;
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  display: flex;
  color: black;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  /*overflow: hidden;*/

}
.rg_item5 {
  width: 291px;
  height: 142px;
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  display: flex;
  color: black;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  /*overflow: hidden;*/
}
.item-title {
  font-family: Montserrat-Bold;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
  top: -8px;
  left: 30px;
}

.rg_item-body {
  //background: red;
  width: 100%;
  height: 100%;
}
.rg_item-body-item {
  width: 189px;
  display: flex;
  gap: 8px;
  //background: greenyellow;
  align-items: flex-start;
  span {
    //background: green;
    width: 162px;
    color: #949494;
    font-family: Montserrat-Light;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
  }
}

.rg_btn {
  font-family: Montserrat-Light;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  text-transform: uppercase;
  background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: unset;
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 15px
}
.rg_btn_prev {
  font-family: Montserrat-Light;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  text-transform: uppercase;
  background: linear-gradient(30deg, #D7D7D7 18.3%, #D7D7D7 39.43%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: unset;
  position: absolute;
  cursor: pointer;
  right: 605px;
  top: 15px
}

.l_1 {
  width: 62px;
  height: 1px;
  background: #930210;
  position: absolute;
  //left: 0;
  //top: 46px;
}
.dot_1 {
  position: absolute;
  left: 62px;
}

.l_2 {
  width: 691px;
  height: 1px;
  background: #930210;
  position: absolute;
  //left: 0;
  //top: 46px;
}
.l_3 {
  width: 48px;
  height: 1px;
  background: #930210;
  position: absolute;
  rotate: 90deg;
  //left: 0;
  //top: 46px;
}

.dot_2 {
  position: absolute;
  left: 734px;
}
.l_4 {
  width: 76px;
  height: 1px;
  background: #930210;
  position: absolute;
  rotate: 90deg;
  //left: 0;
  //top: 46px;
}

.dot_3 {
  position: absolute;
  left: 734px;
}

.l_5 {
  width: 50px;
  height: 1px;
  background: #930210;
  position: absolute;
  //left: 0;
  //top: 46px;
}
.l_6 {
  width: 76px;
  height: 1px;
  background: #930210;
  position: absolute;
  rotate: 90deg;
  //left: 0;
  //top: 46px;
}

.dot_4 {
  position: absolute;
  left: 734px;
}
.l_7 {
  width: 60px;
  height: 1px;
  background: #930210;
  position: absolute;
  rotate: 90deg;
  //left: 0;
  //top: 46px;
}
.l_8 {
  position: absolute;
  //left: 0;
  //top: 46px;
}
.l_9 {
  position: absolute;
  //left: 0;
  //top: 46px;
}

.dot_5 {
  position: absolute;
  left: 734px;
}
//end Roadmap GSAP


//ROADMAP SLIDER

.antd-roadmap {
  width: 1240px;
  height: 325px;
  border-radius: 25px;
  background: url("../src/assets/roadmap-slider-bg.svg");
  background-repeat: no-repeat;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 75px;
}

.left-button-vertical  {
  display: flex;
  width: 35px;
  height: 293px;
  border-radius: 26px;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 16px;
  top: 16px;
  transition: .3s ease-in-out;
}
.left-button-vertical img {
  width: 13px;
  height: 29px;
}
.right-button-vertical img {
  width: 13px;
  height: 29px;
}
.left-button-vertical:hover {
  border-radius: 26px;
  background: #F4F4F4;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  cursor: pointer;
}
.right-button-vertical {
  display: flex;
  width: 35px;
  height: 293px;
  border-radius: 26px;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 16px;
  top: 16px;
  transition: .3s ease-in-out;
}

.right-button-vertical:hover {
  border-radius: 26px;
  background: #F4F4F4;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  cursor: pointer;
}
.slider-content {
  display: flex;
  width: 100%;
  gap: 32px;
  margin-top: 60px;
}
.sub-content {
  width: 415px;
  height: 140px;
  color:  #949494;
  font-family: Montserrat-Light;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about-roadmap-carousel {
  &.ant-carousel {
    width: 1000px!important;
    margin-left: 125px!important;
    margin-top: 38px!important;
  }
}

.line-slider {
  position: absolute;
}
.dot {
  position: absolute;
}

.title1 {
  font-family: Montserrat-Bold;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.year {
  background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Montserrat-Bold;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.roadmap-about {
  margin-top: 41px;
}

.roadmap-about-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 17px
}
.roadmap-about-header-line_1 {
  width: 97px;
  height: 1px;
  background: linear-gradient(90deg, rgba(195, 16, 29, 0.00) 0%, #75030B 100%);
}
.roadmap-about-header-title {
  font-family: Montserrat-Bold;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  text-transform: uppercase;
  background: linear-gradient(#75030B, #930210, #B4001E, #DA291C);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.roadmap-about-header-subtitle {
  font-family: Montserrat-Bold;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: linear-gradient(#DA291C, #B4001E, #930210, #75030B);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 51px;
}
//END ROADMAP SLIDER

.roadmap-home {
  margin-top: 41px;
}

.roadmap-home-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 17px
}
.roadmap-home-header-line_1 {
  width: 97px;
  height: 1px;
  background: linear-gradient(90deg, rgba(195, 16, 29, 0.00) 0%, #75030B 100%);
}
.roadmap-home-header-title {
  font-family: Montserrat-Bold;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  text-transform: uppercase;
  background: linear-gradient(#75030B, #930210, #B4001E, #DA291C);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.roadmap-home-header-subtitle {
  font-family: Montserrat-Bold;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: linear-gradient(#DA291C, #B4001E, #930210, #75030B);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: -45px;
}

@media (max-width: 1300px) {
  .web-roadmap-display {
    display: none;
  }
  .roadmap-home {
    width: 100%;
    padding: 16px;
  }
  .roadmap-home-header-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .roadmap-home-header-subtitle {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }
  .roadmap-home-header {
    gap: 6px;
  }
  .roadmap-home-header-line_1 {
    width: 24px;
  }
  .previous {
    top: -53px!important;
  }
  .next {
    top: -53px!important;
  }
}
@media (min-width: 1300px) {
  .mobile-roadmap-display {
    display: none;
  }
  .nft-details-mobile-header {
    display: none!important;
  }

}
@media (max-width: 350px) {
  .more-button {
    width: 258px!important;
  }
}

.nft-details-mobile-header {
  width: 770px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 24px;
  &_collection-name {
    display: flex;
    gap: 6px;
    align-items: center;
    img {
      width: 18px;
      height: 18px;
    }

    span {
      font-family: Montserrat-Light;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      background: linear-gradient(30deg, #75030B, #930210, #B4001E, #DA291C);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &_owner-name {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &__name {
      font-family: Montserrat-Bold;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &__owner {
      color: #949494;
      font-family: Montserrat-Light;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      span {
        color: #949494;
        font-family: Montserrat-Bold;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}

///*Mobile Roadmap Home*///
.mobile-roadmap-display {
  margin-top: 118px;
  text-align: -webkit-center;
}
.mobile-roadmap-home {
  width: 100%;
  //min-width: 320px;
  //max-width: 1200px;
  border-radius: 25px;
  //background: grey;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  //display: flex;
  //flex-direction: column;
  position: relative;
  padding-bottom: 24px;
}
.previous {
  width: 175px;
  position: absolute;
  left: 0;
  top: -60px;
  text-align: start;
  span {
    color: #D7D7D7;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 114.5%;
    text-transform: uppercase;
  }
}
.next {
  width: 175px;
  position: absolute;
  right: 0;
  top: -60px;
  text-align: right;
  span {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 114.5%; /* 18.32px */
    text-transform: uppercase;
    background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

}

.previous:hover {
  cursor: pointer;
}
.next:hover {
  cursor: pointer;
}

.carousel-mobile-roadmap {
  padding: 0 24px;
  //background: #D7D7D7;
  //height: 230px!important;
}

.mobile-slider-content {
  //width: 100%;
  //margin-top: 12px;
  //background: green;
  gap: 12px;
  height: 230px;
  display: flex!important;
  flex-direction: column;
  &_item {
    display: flex;
    gap: 8px;
    align-items: flex-start;
    img{
      width: 18px;
    }
    span {
      color: #949494;
      font-family: Montserrat-Light;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}


.mobile-roadmap-title {
  font-family: Montserrat-Light;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  //position: absolute;
  margin-top: -8px;
  text-align: center;
  span {
    background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Montserrat-Light;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.more-button {
  display: flex;
  width: 302px;
  height: 50px;
  padding: 24px 44px;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  border: 1px solid #75030B;
  background: #FFF;
  color: #B21815;
  font-family: Montserrat-Light;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%;
  margin-top: 24px;
}

.h-100 {
  height: 100%;
  //background: red;
  gap: 12px;
  display: flex!important;
  flex-direction: column;
}
//end

.nft-dropdown {
  border-radius: 15px;
  box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  //&.ant-dropdown-menu-title-content {
  //  font-family: Montserrat;
  //  font-size: 16px;
  //  font-style: normal;
  //  font-weight: 600;
  //  line-height: normal;
  //  background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
  //  background-clip: text;
  //  -webkit-background-clip: text;
  //  -webkit-text-fill-color: transparent;
  //}
  &.ant-dropdown {
    z-index: 5555555555;
    //width: 224px;
    //border-radius: 15px;
    background: #FFF!important;
    //box-shadow: 0px 4px 13.4px 0px rgba(0, 0, 0, 0.13);
  }
}

.rotate-arrow {
  rotate: 180deg;
}

.rotate-arrow-transition {
  transition: .2s ease;
}
.market-nft-dropdown-items {
  color: #949494!important;
  font-family: Montserrat-Light;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}
.market-nft-dropdown-items:hover {
  font-family: Montserrat-Light;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dropdown-routes {
  display: flex;
  flex-direction: column;
  margin-top: -6px;
  gap: 24px;
  align-items: center;
  span {
    color: #949494;
    font-family: Montserrat-Light;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.responsive-position {
  margin-top: -180px;
}
.actions-position {
  height: 266px;
  padding: 42px 24px;
  margin-top: 70px;
}
.button-position {
  width: 100%;
  height: 70px;
  margin-top: 40px;
}

.transaction-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  &_title {;
    font-family: Montserrat-Bold;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }
  &_info {
    color: #949494;
    font-family: Montserrat-Light;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 114.5%;
    text-align: center;
  }
}

:where(.css-dev-only-do-not-override-1okl62o).ant-badge .ant-badge-count {
  min-width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  background: linear-gradient(30deg, #75030B 18.3%, #930210 39.43%, #B4001E 60.57%, #DA291C 81.7%);
}

.login-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}

.login-btn {
  padding: 4px 8px;
  width: 100px;
  border-radius: 8px;
  border: unset;
  background: #DA291C;
  color: #FFF;
  font-family: "NotoSans REG";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.login-modal {
  &.ant-modal-root .ant-modal-mask {
    background-color: #ffffff;
  }
}
.date-check {
  &.ant-switch.ant-switch-checked {
    background: #DA291C;
  }
  &.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: #DA291C;
  }
}

